import { getAllCountries, getLocaleByName } from 'country-locale-map';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector
} from '@angular/core';
import { LocalService } from '@shopiroller/i18n';
import { MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppConfigurationService, AppSettingService, IAppConfiguration } from '@shopiroller/core';
import { PaymentService } from 'src/app/modules/store-settings';
import { selectCurrentAppPaymentInfo } from '@shopiroller/stores';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.Default
})
export abstract class BaseComponent {
  public appId = '';
  public isLoading = false;
  public defaultCurrency = '';
  public defaultLocale = '';
  public appConfiguration: IAppConfiguration;
  public store: Store;
  public router: Router;
  public localService: LocalService;
  public paymentService: PaymentService;
  public translateService: TranslateService;
  public messageService: MessageService;
  public appSettingService: AppSettingService;
  public changeDetector: ChangeDetectorRef;
  public appConfigurationService: AppConfigurationService;
  public get currencyInformationFetched(): boolean {
    return this.defaultCurrency != '' && this.defaultLocale != '';
  }

  constructor(public injector: Injector) {
    this.store = this.injector.get(Store);
    this.router = this.injector.get(Router);
    this.localService = this.injector.get(LocalService);
    this.paymentService = this.injector.get(PaymentService);
    this.translateService = this.injector.get(TranslateService);
    this.messageService = this.injector.get(MessageService);
    this.appSettingService = this.injector.get(AppSettingService);
    this.changeDetector = this.injector.get(ChangeDetectorRef);
    this.appConfigurationService = this.injector.get(AppConfigurationService);
    this.appId = this.appSettingService.getCurrentAppId();
    this.appConfiguration = this.appConfigurationService.configurations;
  }

  public reloadPage(): void {
    window.location.reload();
  }

  public reloadComponent(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }

  public translate(phrase: string): string {
    return this.translateService.instant(phrase);
  }

  public showErrorMessage(msg = 'SHARED.MESSAGES.REQUEST_FAILED'): void {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('SHARED.MESSAGES.ERROR'),
      detail: this.translateService.instant(msg)
    });
  }

  public showSuccessMessage(
    msg = 'SHARED.MESSAGES.FULFILLED_SUCCESSFULLY'
  ): void {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('SHARED.MESSAGES.SUCCESS'),
      detail: this.translateService.instant(msg)
    });
  }

  public showInfoMessage(msg:string): void {
    this.messageService.add({
      severity: 'info',
      summary: this.translateService.instant('SHARED.MESSAGES.INFO'),
      detail: this.translateService.instant(msg)
    });
  }

  public setupCurrencyInfo(): void {
    this.store
      .select(selectCurrentAppPaymentInfo)
      .subscribe((paymentInfo: any) => {
        if (!paymentInfo) return;
        this.defaultCurrency = paymentInfo.defaultCurrency;
        const countries = getAllCountries();
        const selectedCountry = countries.find(
          (country) => country.currency === this.defaultCurrency
        )?.name;
        if (selectedCountry) {
          this.defaultLocale =
            getLocaleByName(selectedCountry as string)
              ?.trim()
              ?.replace('_', '-') || '';
        } else {
          this.defaultLocale = 'en-US';
        }
        const language = this.defaultLocale.substring(0, 2);
        this.localService.registerLocale(language);
        this.changeDetector.detectChanges();
      });
  }

  public changeHeaderBgColor(defaultColor = false): void {
    const ktheader = document.getElementById('kt_header');
    if (ktheader && !defaultColor) {
      ktheader.style.zIndex = '0';
    }
    if (ktheader && defaultColor) {
      setTimeout(() => {
        ktheader.style.zIndex = '3';
      }, 200);
    }
  }

  public toggleShadowStyleForHeaderSection(isShadow = true): void {
    const ktheader = document.getElementById('kt_header');
    if (ktheader) {
      if (isShadow == true) {
        ktheader.classList.remove('normal-bg-kt-header');
        ktheader.classList.add('shadow-bg-kt-header');
      } else {
        setTimeout(() => {
          ktheader.classList.remove('shadow-bg-kt-header');
          ktheader.classList.add('normal-bg-kt-header');
        }, 150);
      }
    }
  }
}
