import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { BizimHesapModel, BizimHesapUpdateModel, IReminderInformation } from '../../data';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class BizimHesapService {
  constructor(private _http: HttpClient) {}

  public getConfiguration(appId: string): Observable<BizimHesapModel> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/bizimHesap`;
    return this._http.get(URL).pipe(map((response:any)=> response.data as BizimHesapModel));
  }

  public setInformation(
    appId: string,
    payload: BizimHesapUpdateModel
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/bizimHesap`;
    return this._http.post(URL, payload);
  }
}
