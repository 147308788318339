/*These two below sections should have same values */

export type featuresNames =
  | 'loggingSystem'
  | 'webSaleChannel'
  | 'mobileSaleChannel'
  | 'shopilink'
  | 'appSumo'
  | 'discount'
  | 'appStore'
  | 'whatsApp'
  | 'cartReminder'
  | 'regionAndLocalization'
  | 'sendy'
  | 'googleAnalytics'
  | 'googleTagManager'
  | 'stockAlerts'
  | 'productReview'
  | 'subscription'
  | 'frillWidget'
  | 'allowPermissionForProject'
  | 'shoppay'
  | 'guestShopping'
  | 'webHook'
  | 'domesticShipping'
  | 'googleShopping'
  | 'magenty'
  | 'easyCollection'
  | 'bizimHesap'
  ;

export enum featuresList {
  'loggingSystem' = 'loggingSystem',
  'webSaleChannel' = 'webSaleChannel',
  'mobileSaleChannel' = 'mobileSaleChannel',
  'shopilink' = 'shopilink',
  'appSumo' = 'appSumo',
  'discount' = 'discount',
  'appStore' = 'appStore',
  'regionAndLocalization' = 'regionAndLocalization',
  'whatsApp' = 'whatsApp',
  'cartReminder' = 'cartReminder',
  'sendy' = 'sendy',
  'googleAnalytics' = 'googleAnalytics',
  'googleTagManager' = 'googleTagManager',
  'stockAlerts' = 'stockAlerts',
  'productReview' = 'productReview',
  'subscription' = 'subscription',
  'frillWidget' = 'frillWidget',
  'allowPermissionForProject' = 'allowPermissionForProject',
  'shoppay' = 'shoppay',
  'guestShopping' = 'guestShopping',
  'webHook' = 'webHook',
  'domesticShipping' = 'domesticShipping',
  'googleShopping' = 'googleShopping',
  'magenty' = 'magenty',
  'easyCollection' = 'easyCollection',
  'bizimHesap' = 'bizimHesap'
}
