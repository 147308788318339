export type SubscriptionFeaturesTypes =
  | 'apps_BackinStockReminder'
  | 'apps_CartReminder'
  | 'apps_WhatsApp'
  | 'catalog_ProductVideo'
  | 'catalog_Variant_max_count'
  | 'payment_birlesikodeme_active'
  | 'payment_credicardatthedoor_active'
  | 'payment_eft_active'
  | 'payment_iyzico_active'
  | 'payment_payatthedoor_active'
  | 'payment_paypal_active'
  | 'payment_paytr_active'
  | 'payment_stripe_active'
  | 'saleschannel_advancedsite_active'
  | 'saleschannel_apisdk_active'
  | 'saleschannel_basicsite_active'
  | 'saleschannel_marketplace_active'
  | 'saleschannel_mobileapp_active'
  | 'saleschannel_shopilink_active'
  | 'saleschannel_socialmedia_active'
  | 'settings_AdvancedShippingSettings'
  | 'settings_LocationBasedRouting'
  | 'settings_MultiCurrency'
  | 'settings_MultiLanguage'
  | 'shopiroller_badge'
  | 'apps_BizimHesap'
  ;

export enum SubscriptionFeaturesEnum {
  'apps_BackinStockReminder' = 'apps_BackinStockReminder',
  'apps_CartReminder' = 'apps_CartReminder',
  'apps_WhatsApp' = 'apps_WhatsApp',
  'catalog_ProductVideo' = 'catalog_ProductVideo',
  'catalog_Variant_max_count' = 'catalog_Variant_max_count',
  'payment_birlesikodeme_active' = 'payment_birlesikodeme_active',
  'payment_credicardatthedoor_active' = 'payment_credicardatthedoor_active',
  'payment_eft_active' = 'payment_eft_active',
  'payment_iyzico_active' = 'payment_iyzico_active',
  'payment_payatthedoor_active' = 'payment_payatthedoor_active',
  'payment_paypal_active' = 'payment_paypal_active',
  'payment_paytr_active' = 'payment_paytr_active',
  'payment_stripe_active' = 'payment_stripe_active',
  'saleschannel_advancedsite_active' = 'saleschannel_advancedsite_active',
  'saleschannel_apisdk_active' = 'saleschannel_apisdk_active',
  'saleschannel_basicsite_active' = 'saleschannel_basicsite_active',
  'saleschannel_marketplace_active' = 'saleschannel_marketplace_active',
  'saleschannel_mobileapp_active' = 'saleschannel_mobileapp_active',
  'saleschannel_shopilink_active' = 'saleschannel_shopilink_active',
  'saleschannel_socialmedia_active' = 'saleschannel_socialmedia_active',
  'settings_AdvancedShippingSettings' = 'settings_AdvancedShippingSettings',
  'settings_LocationBasedRouting' = 'settings_LocationBasedRouting',
  'settings_MultiCurrency' = 'settings_MultiCurrency',
  'settings_MultiLanguage' = 'settings_MultiLanguage',
  'shopiroller_badge' = 'shopiroller_badge',
  'apps_BizimHesap' ='apps_BizimHesap'
}
