export interface BizimHesapUpdateModel {
    firmId: string,
 }

export interface BizimHesapModel {
    firmId: string,
    appId: string,
    createDate: string,
    updateDate: string,
    isActive: boolean
}