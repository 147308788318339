// USA
export const EN_LOCALE = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language'
    },
    TEMPLATE: {
      // translations in this block are using in some layout components such as top header
      WELCOME: 'Welcome',
      PROFILE: 'Profile',
      SETTINGS: 'Settings',
      ACCOUNT: 'Account',
      LOGOUT: 'Logout',
      HELP_CENTER: 'Help Center',
      GIVE_FEEDBACK: 'Give Feedback',
      SUPPORT_REQUEST: 'Support Request',
      ALL_PROJECT: 'All Project',
      ALL_STORES:'All Stores',
      WHATS_NEW: "What's New"
    },
    MENU: {
      DASHBOARD: 'Dashboard',
      ORDERS: 'Orders',
      CATALOG: {
        TITLE: 'Catalog',
        PRODUCTS: 'Products',
        CATEGORIES: 'Categories',
        BRANDS: 'Brands',
        SHOWCASE: 'Showcase',
        VARIANT: 'Variant'
      },
      CLIENTS: 'Clients',
      STATISTIC: 'Statistic',
      SALES_CHANNELS: {
        ADD_NEW_SALES_CHANNEL: 'Add New Sales Channel',
        TITLE: 'Sales Channels',
        WEBSITE: 'Website',
        MOBILE: 'Mobile App',
        FACEBOOK_INSTAGRAM: 'Facebook & Instagram'
      },
      SETTINGS: 'Settings',
      GENERAL_SETTINGS: 'General Settings',
      PROJECT: 'Project',
      CONFIGURATION: {
        TITLE: 'Configuration',
        GENERAL_SETTINGS: {
          STORE_INFORMATION: 'Store Information',
          TITLE: 'General Settings',
          PROJECT_INFORMATION: 'Project Information',
          INVOICES_PACKAGES: 'Invoices and Packages',
          STAFF_ACCOUNT: 'Staff Accounts'
        },
        STORE_SETTINGS: {
          TITLE: 'Store Settings',
          LANGUAGE_LOCALIZATION: 'Language & Localization',
          STORE_INFORMATION: 'Store Information',
          PAYMENT: 'Payment',
          LEGAL_PAGES: 'Legal Pages',
          SHIPPING_AND_DELIVERY: 'Shipping and Delivery',
          EMAIL_NOTIFICATION: 'E-mail Notification',
          SLIDE_MANAGEMENT: 'Slide Management',
          STOCK_LOCATIONS: 'Stock Locations',
          TAX_SETTINGS: 'Tax Settings',
          CUSTOMER_GROUPS: 'Customer Groups',
          REGION_LOCALIZATION: 'Regions & Localization',
          LANGUAGE: 'Language'
        }
      },
      APPS: 'Apps',
      MY_APPS: 'My Apps',
      APP_STORE: 'App Store'
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
        CREATE_AN_ACCOUNT: 'Create an Account',
        CREATE_NEW_ACCOUNT: 'Create New Account',
        MOTTO: 'Headless <br/> E-commerce CMS',
        MOTTO_DESCRIPTION:
          'We are here to bring the power of e-commerce to every business owner and every creator who hate complexity but wants results.',
        AND: 'and',
        TERMS_OF_CONDITION: 'Terms of Conditions',
        PRIVACY_POLICY: 'Privacy Policy',
        PRIVACY_STATEMENT: 'By signing up, you agree to Shopiroller’s',
        WHOOPS: 'Whoops',
        GO_TO_LOGIN: 'Go to login page'
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
        WELCOME_BACK: 'Welcome Back!',
        NEED_SHOPIROLLER_ACCOUNT: 'Need a Shopiroller account?',
        SIGN_IN_WTIH_GOOGLE: 'Sign in with Google',
        FAILED: 'Login failed',
        UserNotFound: 'Your username or password is incorrect.',
        WrongPassword: 'Your username or password is incorrect.',
        UserLockedOut:
          'Your account has just been locked out, wait some minutes and try again.',
        NotActivatedEmail: 'Your email is not confirmed yet'
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        EMAIL_SENT: "We've just sent a reset email, please check your mailbox.",
        SUCCESS: 'Your account has been successfully reset.',
        PAGE_HEADER: 'Reset Your Password',
        PAGE_SUB_HEADER:
          "Fear not. Just let us know the email you use to sign in to Shopiroller and we'll help you get your password back.",
        RESET_PASSWORD_PAGE_HEADER:
          'Dear user please type in a new password (at least 6 characters long) for your account.',
        RESET_BUTTON: 'Send Password Reset Email',
        BACK_BUTTON: 'Return to login',
        RESET_PASSWORD_EMAIL_SENT_MESSAGE_HEADER: 'Great! ',
        RESET_PASSWORD_EMAIL_SENT_MESSAGE: 'You have changed password',
        REDIRECT_TO: 'You will be redirecting to login in ',
        RESET_PASSWORD: 'Reset password',
        RESET_PASSWORD_INVALID_TOKEN:
          'Your token is expired, please go to <a href="/auth/forgot-password">forgot password</a> page and start this procedure once again'
      },
      CHANGE_PASSWORD: {
        TITLE: 'Change Password'
      },
      REGISTER: {
        verifyPhoneNumber: 'Verify Phone Number',
        TITLE: 'Sign Up Now',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
        LOGIN: 'Log in',
        SIGN_UP_WITH_GOOGLE: 'Sign up with Google',
        FAILED: 'Registration Failed'
      },
      CONFIRM_EMAIL: {
        OPS: 'Oops!',
        RESEND_MSG:
          "It seems your activation token has expired, we've just sent a new email to you please check your mailbox and try once again."
      },
      SUCCESSFUL_PROCESS: {
        REDIRECT_TO_LOGIN: 'You will be redirected to login in',
        SECONDS: 'seconds',
        SUCCESSFUl_ACTIVATION_EMAIL_RESEND_MESSAGE:
          'We just sent a new confirmation email to you, you need to check your mailbox in order to confirm your email.',
        SUCCESSFUl_REGISTRATION_MESSAGE:
          'Your registration has been successfully completed, you need to check your mailbox in order to confirm your email.',
        SUCCESSFUl_REGISTRATION_MESSAGE_HEADER: 'Congrats!',
        SUCCESSFUl_RESET_PASSWORD_MESSAGE: 'You have changed the password',
        SUCCESSFUl_RESET_PASSWORD_MESSAGE_HEADER: 'Great! ',
        SUCCESSFUl_CONFIRM_EMAIL_MESSAGE:
          'Your email has been successfully verified.'
      },
      INPUT: {
        PHONE_NUMBER: 'Phone Number',
        EMAIL: 'Email',
        EMAIL_ADDRESS: 'Email Address',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
        RE_TYPE_PASSWORD: 'Re-type Password'
      },
      VALIDATION: {
        RESEND_ACTIVATION_EMAIL:
          "If you didn't receive your activation email yet, please click on",
        RESEND_LINK: 'the re-send link',
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        System: 'An unknown error occurred in the application',
        systemException: 'An unknown error occurred in the application',
        UserNotFound: 'User Not Found!',
        EntityNotFound: 'Entity Not Found',
        UserDoesNotHavePassword: 'User Does Not Have Password',
        NoPassword: 'Password is empty',
        UserLockedOut: 'User has Locked out',
        BadRequest: 'The data sent to the server is incorrect',
        DuplicateUserName: 'Username already exists',
        WrongPassword: 'Password is incorrect',
        PASSOWRD_CONFIRM_PASSWORD_NOT_MATCH:
          "'Password' and 'Confirm Password' didn't match.",
        undefined: 'An unknown error occurred in the application',
        EMAIL_IS_INVALID: 'Email is invalid',
        EMAIL_IS_REQUIRED: 'Email is required',
        EMAIL_MIN_LENGTH: 'Email should have at least 3 symbols',
        EMAIL_MAX_LENGTH: 'Email should have at most 256 symbols',
        PASSWORD_IS_REQUIRED: 'Password is required',
        PASSWORD_MIN_LENGTH: 'Password should have at least 6 symbols',
        PASSWORD_MAX_LENGTH: 'Password should have at most 32 symbols',
        CPASSWORD_IS_REQUIRED: 'Confirm password is required',
        CPASSWORD_MIN_LENGTH: 'Confirm password should have at least 6 symbols',
        CPASSWORD_MAX_LENGTH: 'Confirm password should have at most 32 symbols',
        FULLNAME_MIN_LENGTH: 'Full name should have at least 3 symbols',
        FULLNAME_MAX_LENGTH: 'Full name should have at most 100 symbols',
        FULL_NAME_IS_REQUIRED: 'Full name is required',
        LimitExceeded:
          "You're not allowed to send more reset password email requests for a while"
      }
    },
    LAYOUT_BUILDER: {
      HEADER_TEXT:
        'The layout builder is to assist your set and configure your preferred project layout specifications and preview it in real-time. The configured layout options will be saved until you change or reset them. To use the layout builder, choose the layout options and click the Preview button to preview the changes.'
    },
    APPLICATIONS: {
      DEMO_STORE: 'Demo Store',
      DEMO_STORE_DESCRIPTION:
        'Build the store with demo data. I want to see how it is.',
      EMPTY_STORE: 'Empty Store',
      EMPTY_STORE_DESCRIPTION: 'I will build everything from scratch.',
      CHANGE_STORE_SALE_CHANNEL:
        'If you want, you can then change it from web sales channel settings.',
      STORE_COUNT: 'Store count',
      STORE_CREATION_FAILED: 'Sorry, your store creation failed!',
      DOMAIN_IS_NOT_AVAILABLE:
        "Your store domain is not available or probably it's on the blacklist, for fixing this issue please change your store name.",
      WE_ARE_CREATING_YOUR_STORE: 'We are creating your store',
      YOUR_STORE_DATA_IS_ADDING: 'Primary data of your store is adding',
      WE_ARE_CREATING_GENERAL_PREFERENCES:
        'We are creating general preferences',
      YOUR_SALES_CHANNEL_ARE_GETTING_READY:
        'Your sales channels are getting ready',
      THE_PROCESS_OF_CREATING_STORE_IS_COMPLETED:
        'The process of creating your store is completed',
      THIS_WOULD_BE_YOUR_INITIAL_URL:
        'This would be your initial URL, after creating the store you would be capable to change it to your desired address',
      CREATE_NEW_STORE: 'Create New Store',
      CREATE_STORE: 'Create Store',
      STORE_NAME: 'Store Name',
      STORE_SUB_DOMAIN: 'Store Subdomain',
      STORE_DEFAULT_CURRENCY: 'Store default currency',
      STORE_DEFAULT_LANGUAGE: 'Store default language',
      NEED_A_DEMO: 'Need a demo',
      STORE_SECTOR: 'Store Sector',
      QUICK_ACTIONS: 'Quick actions',
      CREATE_NEW_PROJECT: 'Create New Project',
      CREATE_PROJECT: 'Create  Project',
      PROJECT_NAME: 'Project name',
      PROJECT_COUNT: 'Project count',
      GOOD_MORNING: 'Good Morning',
      GOOD_AFTERNOON: 'Good Afternoon',
      GOOD_EVENING: 'Good Evening',
      GREETING_MESSAGE: 'We are happy to be here. Have a nice day at work',
      NO_APPLICATION_COMPONENT_MESSAGE:
        'Good to have you here, <br/> come on create a <b>new project</b> ',
      LAST_ACTIVITY: 'Last Activity',
      SUCCESSFUL_EDIT: 'The application has been edited successfully',
      SUCCESSFUL_DELETE: 'The application has been deleted successfully',
      NO_SEARCH_RESULT_COMPONENT_MESSAGE:
        'Oh, nothing appears. Try again by changing your search criteria.',
      NO_RECORDS_FOUND: 'No records found',
      DELETE_PROJECT: 'Delete Project',
      DASHBOARD: 'Dashboard',
      PRODUCTS: 'products',
      ORDERS: 'Orders',
      STATISTICS: 'Statistics',
      DELETE_APPLICATION_WARNING:
        'After your approval, your application will be deleted. After you delete your application',
      LOSING_CONTENTS_WARNING:
        'all the contents of your application such as products, categories, and so on will be deleted as well and you will lose access to them completely.',
      IRREVERSIBLE_WARNING:
        "Delete application is irreversible, even if you accidentally delete your application, it cannot be undone. If you are confirming the delete of your application, please enter your Shopiroller password and click the 'Delete' button..."
    },
    SHARED: {
      UNSPECIFIED: 'Unspecified',
      UNKNOWN: 'Unknown',
      REJECTED: 'Rejected',
      STORE: 'Store',
      PROJECT_KEY: 'Project Key',
      SURNAME: 'Surname',
      MANAGE: 'Manage',
      GOOGLE: 'Google',
      SUGGEST: 'Suggest',
      AMOUNT: 'Amount',
      OFFER: 'Offer',
      ACCEPT: 'Accept',
      KG: 'kg',
      CM: 'cm',
      LENGTH: 'Length',
      HEIGHT: 'Height',
      WIDTH: 'Width',
      WEIGHT: 'Weight',
      DISTRICT: 'District',
      CITY: 'City',
      ZIP_CODE: 'Zip Code',
      REQUEST: 'Request',
      VALUE: 'Value',
      METHOD: 'Method',
      TYPE: 'Type',
      TRIGGER: 'Trigger',
      URL: 'Url',
      APPLICATION: 'Application',
      COLLECTION: 'Collection',
      CURRENT_STATUS: 'Current status',
      SHOPIPAY: 'Shopipay',
      MESSAGE: 'Message',
      SHOPIROLLER: 'Shopiroller',
      ADDRESS: 'Address',
      CONFIRMED: 'Confirmed',
      AWAITING_FOR_APPROVAL: 'Awaiting for approval',
      PHONE_NUMBER: 'Phone Number',
      VIDEO: 'Video',
      PHOTO: 'Photo',
      PUBLISH: 'Publish',
      UNPUBLISH: 'Unpublish',
      SECURITY_CODE: 'Security Code',
      EXPIRATION_DATE: 'Expiration date',
      CVC: 'CVC',
      BRAND: 'Brand',
      DETAIL: 'Detail',
      AMOUNT_PAID: 'Amount Paid',
      DATE: 'Date',
      CUSTOMER: 'Customer',
      TRANSACTIONS: 'Transactions',
      CREDIT_CARD: 'Credit Card',
      CARDHOLDER_NAME: 'Cardholder Name',
      NAME: 'Name',
      PENDING: 'Pending',
      TRANSFERRED: 'Transferred',
      CANCELED: 'Canceled',
      SUCCEEDED: 'Succeeded',
      FAILED: 'Failed',
      PER: 'Per',
      PER_MONTH: 'Per <br/> Month',
      PER_YEAR: 'Per <br/> Year',
      MONTHLY: 'Monthly',
      YEARLY: 'Yearly',
      CURRENCY: 'Currency',
      REGION: 'Region',
      HOURS: 'Hours',
      WEBSITE: 'Website',
      WHATSAPP: 'WhatsApp',
      SUPPORT: 'Support',
      PREVIOUS: 'Previous',
      NEXT: 'Next',
      NONE: 'None',
      INTEGRATE: 'integrate',
      COMING_SOON: 'Coming Soon',
      HERE: 'Here',
      ATTACH: 'Attach',
      CODE: 'Code',
      CONFIGURATION: 'Configuration',
      UPGRADE: 'Upgrade',
      DOWNGRADE: 'Downgrade',
      PACKAGE: 'Package',
      BUSINESS: 'Business',
      EMAIL: 'Email',
      TITLE: 'Title',
      DESCRIPTION: 'Description',
      DOMAIN: 'Domain',
      SUBDOMAIN: 'Sub domain',
      LOGO: 'Logo',
      FAVICON: 'FavIcon',
      SEARCH: 'Search',
      TRANSFER: 'Transfer',
      PAY_AT_DOOR: 'Pay at door',
      PAYPAL: 'Paypal',
      ONLINE: 'Online',
      ONLINE_3DS: 'Online 3DS',
      STRIPE: 'Stripe',
      VISIBLE: 'Visible',
      DISABLE: 'Disable',
      ENABLE: 'Enable',
      WEB: 'Web',
      MOBILE: 'Mobile',
      PRODUCT: 'Product',
      SERVICE: 'Service',
      QTY: 'Qty',
      TOTAL: 'Total',
      LINK: 'Link',
      COPY: 'Copy',
      PREVIEW: 'Preview',
      SETTINGS: 'Settings',
      SERVICES: 'Services',
      TRANSLATE: 'Translate',
      OR: 'OR',
      CUSTOMIZE: 'Customize',
      IOS: 'IOS',
      ANDROID: 'Android',
      PLATFORM: 'Platform',
      SAVE_CHANGES: 'Save changes',
      NON_CONNECTED: 'NonConnected',
      CONNECTED: 'Connected',
      PASSWORD: 'Password',
      PROJECT: 'Project',
      ADD_NEW_TRANSLATE: 'Add new translate',
      ADDED_FILE: 'Added File',
      REPLACE_FILE: 'Replace file',
      ADD_FILE: 'Add file',
      CHANGE_FILE: 'Change file',
      UPLOAD_CONTINUE: 'Upload & continue',
      MORE_ACTIONS: 'More Actions',
      IMPORT: 'Import',
      EXPORT: 'Export',
      MADE_WITH: '© 2023 Shopiroller',
      CHOOSE_AN_ITEM: 'Choose an item',
      WELCOME: 'Welcome',
      YOUR_PASSWORD: 'Your password',
      REASON: 'Reason',
      RESEND: 'Resend',
      SEC: 'Sec',
      CONTINUE: 'Continue',
      CHANGE: 'Change',
      LANGUAGE: 'Language',
      E_MAIL: 'E-mail',
      PHONE: 'Phone',
      ENGLISH: 'English',
      TURKISH: 'Turkish',
      FULL_NAME: 'Full Name',
      ACCOUNT: 'Account',
      FIELD_IS_EMPTY: '{{field}} is empty',
      DONT: "Don't",
      THIS_ACTION_CANT_BE_UNDONE: 'This action cannot be undone.',
      DEFAULT: 'Default',
      TIMELINE: 'Timeline',
      APPLY: 'Apply',
      TODAY: 'Today',
      YESTERDAY: 'Yesterday',
      LAST_7_DAYS: 'Last 7 Days',
      LAST_30_DAYS: 'Last 30 Days',
      THIS_MONTH: 'This Month',
      LAST_MONTH: 'Last Month',
      CUSTOM_RANGE: 'Custom Range',
      YEAR: 'Year',
      MONTH: 'Month',
      WEEK: 'Week',
      DAY: 'Day',
      HOUR: 'Hour',
      MINUTE: 'Minute',
      SECOND: 'Second',
      YES: 'Yes',
      NO: 'No',
      REMOVE: 'Remove',
      FINISH: 'Finish',
      LEARN_MORE: 'Learn more',
      MORE_INFO: 'More info',
      GO_BACK: 'Go back',
      SELECT_DATE_TIME: 'Select date & time',
      MIN: 'Min',
      MAX: 'Max',
      JUST_NOW: 'Just Now',
      AGO: ' ago',
      AGO_PLURAL: 's ago',
      PRICE: 'Price',
      MIN_PRICE: 'Min price',
      MAX_PRICE: 'Max price',
      CANCEL: 'Cancel',
      SAVE: 'Save',
      CLOSE: 'Close',
      EDIT: 'Edit',
      DELETE: 'Delete',
      CONFIRM_DIALOG_TEXT: 'Are you sure you want to complete this operation?',
      VERSION: 'Version',
      PRIVACY_POLICY: 'Privacy Policy',
      TERMS_OF_CONDITION: 'Terms of condition',
      TERMS_OF_USE: 'Terms of use',
      LOAD_MORE: 'Load More',
      ACTIONS: 'Actions',
      SORT_BY: 'Sort By',
      DEFAULT_LANG_CANT_BE_DELETED:
        'This is the default language and cannot be deleted',
      LANGUAGE_SUPPORTED: 'Language supported',
      LANGUAGE_NOT_SUPPORTED: 'Language not supported',
      QUEUE: 'Queue',
      CATEGORY: 'Category',
      PRODUCT_TOTAL: 'Product Total',
      SUB_CATEGORY_TOTAL: 'Sub Category Count',
      STATUS: 'Status',
      ACTIVE: 'Active',
      PASSIVE: 'Passive',
      CHOOSE: 'Choose',
      FIELD_IS_REQUIRED: '{{field}} is required',
      FIELD_IS_INVALID: '{{field}} is invalid',
      DEFAULT_LANGUAGE_NOT_SET: 'The default language for {{field}} is not set',
      FIELD_MIN_MAX_BOUNDARY_VALIDATION:
        '{{field}} should have at least {{minLength}} and  at most {{maxLength}} symbols',
      FIELD_IS_INVALID_FOR_ONE_OF_LANGUAGES:
        "'{{field}}'  is not valid for one of the selected languages",
      NO_RECORDS_FOUND: 'No records found.',
      ORDER_INDEX_UPDATED: 'Order index updated successfully',
      FILTER: 'Filter',
      START_DATE: 'Start date',
      END_DATE: 'End date',
      DATE_ERROR: "The start date can't be greater than the end date",
      CLEAR_ALL_FILTER: 'Clear All Filter',
      APPLY_FILTER: 'Apply Filter',
      APPLY_FILTER_ERROR: "You didn't make any change yet",
      CREATED_OLDEST: 'Created (oldest first)',
      CREATED_NEWEST: 'Created (newest first)',
      FILE_UPLOADER: {
        DELETE_TITLE: 'Image will be deleted',
        CHOOSE_LABEL: 'Choose',
        UPLOAD_LABEL: 'Upload',
        CANCEL_LABEL: 'Cancel',
        DROP_MSG: 'Drop your image file here or click to upload',
        MAX_SIZE: 'The maximum file size should be 4 MB',
        VALID_TYPE: 'Valid type for the image are png, jpg, jpeg',
        IMAGE_NOT_UPLOADED: 'Image not uploaded',
        FEATURE_IMAGE: 'Click on your desire image to be the featured image',
        FEATURE_IMAGE_ERROR:
          'You should upload images and then choose your featured image',
        FEATURE_IMAGE_TOOLTIP: 'This is the main image of the product',
        MAXIMUM_COUNT_EXCEEDED:
          'Maximum number of files exceeded, limit is {{limit}} at most'
      },
      VALIDATION: {
        ENTITY_NOT_FOUND: 'Entity not found',
        EMAIL_IS_INVALID: 'Email is invalid',
        EMAIL_IS_REQUIRED: 'Email is required',
        EMAIL_MIN_LENGTH: 'Email should have at least 3 symbols',
        EMAIL_MAX_LENGTH: 'Email should have at most 360 symbols',
        EMAIL_DECLARED_BEFORE: 'Emails have been declared before.',
        PHONE_IS_INVALID: 'Phone is invalid',
        PHONE_NUMBER_IS_INVALID: 'Phone Number is invalid',
        MIN_LENGTH: 'The field should have at least 2 symbols',
        MAX_LENGTH: 'The field should have at most of 255 symbols',
        FIELD_REQUIRED: 'This field is required',
        WEB_URL_IS_INVALID: 'Web URL is invalid',
        PAGE_URL_IS_INVALID: 'Page URL is invalid',
        IMAGE_NOT_UPLOADED: 'Image not uploaded',
        IMAGE_MAX_SIZE: 'The maximum file size should be 4 MB',
        IMAGE_VALID_TYPE: 'Valid type for the image are PNG,JPG,JPEG',
        OUT_OF_RANG_2_60: '{{field}} must be between  2 - 60',
        MIN_LENGTH_ERROR:
          'The field should have at least {{minlength}} symbols',
        MAX_LENGTH_ERROR:
          'The field should have maximum of {{maxlength}} symbols',
        FIELD_MIN_LENGTH_ERROR:
          '{{field}} should have at least {{minlength}} symbols',
        FIELD_MAX_LENGTH_ERROR:
          '{{field}} should have maximum of {{maxlength}} symbols',
        ID_LENGTH: 'The Id field length is 24 characters',
        NO_EMPTY_STRING: 'Empty string is not valid for this field!',
        NO_SPACE: 'No spaces allowed',
        MAX_LENGTH_EXCEEDED: 'You exceeded max length of this field'
      },
      MESSAGES: {
        FULFILLED_SUCCESSFULLY: 'Request fulfilled successfully',
        REQUEST_FAILED: 'Request failed',
        FETCH_FAILED:
          'Fetching data for the current page failed, please refresh the page',
        NOT_FOUNT: 'Entity not found',
        NO_SEARCH_RESULT: 'Oh, nothing appears. Try changing the filter terms',
        SUCCESS: 'Success',
        INFO: 'Info',
        WARN: 'Warn',
        ERROR: 'Error',
        FETCHING_FAILED: 'Fetching {{field}} failed',
        SUCCESSFUL_ADD: 'Adding {{case}} fulfilled successfully',
        SUCCESSFUL_EDIT: 'Editing {{case}} fulfilled successfully',
        SUCCESSFUL_DELETE: '{{case}} deletion fulfilled successfully',
        FAILED_ADD: 'Unfortunately, adding {{case}} failed!',
        FAILED_EDIT: 'Unfortunately, editing {{case}} failed!',
        FAILED_EDIT_OPERATION: 'Unfortunately, the edit operation failed!',
        FAILED_DELETION: 'Unfortunately, {{case}} deletion failed!',
        CONFIRM_ACTION: 'Are you sure that you want to perform this action?',
        FEATURE_IS_NOT_AVAILABLE_IN_CURRENT_PACKAGE:"Sorry, the current feature is not available in the current package, please click on your package name on the top right corner to upgrade it."
      }
    },
    STORE_SETTINGS: {
      TITLE: 'Store Settings',
      STORE_INFORMATION: 'Store Information',
      STORE_DEFINITIONS: 'Store Definitions',

      SHOP_SETTING: 'Shop Settings',
      LANGUAGE_LOCALIZATION_PAGE: {
        DEFAULT_LANGUAGE: 'Default language'
      },
      STORE_INFORMATION_PAGE: {
        STORE_NAME: 'Store Name',
        STORE_NAME_MIN_LENGTH: 'Store Name should have at least 2 symbols',
        STORE_NAME_MAX_LENGTH: 'Store Name should have at most 255 symbols',
        STORE_CONTACT_EMAIL: 'Store Contact Email',
        STORE_SECTOR: 'Store Industry',
        STORE_DEFINITIONS_DESCRIPTION:
          'Shopiroller and your customers will use this information to contact you.',
        STORE_ADDRESS: 'Store Address',
        STORE_ADDRESS_DESCRIPTION:
          'When legal information about your company needs to be used, the information here will be used.',
        BUSINESS_OFFICIAL_NAME: 'Legal Name of Company',
        BUSINESS_OFFICIAL_NAME_MIN_LENGTH:
          'Legal Name of Company should have at least 4 symbols',
        BUSINESS_OFFICIAL_NAME_MAX_LENGTH:
          'Legal Name of Company should have at most 32 symbols',
        PHONE: 'Phone',
        STREET: 'Street',
        STREET_MAX_LENGTH: 'Street should have at most 100 symbols',
        CITY: 'City',
        CITY_MAX_LENGTH: 'City should have at most 90 symbols',
        POST_CODE: 'Post Code',
        POST_CODE_MAX_LENGTH: 'Post Code should have at most 12 symbols',
        STORE_CURRENCY: 'Store Currency',
        STORE_CURRENCY_DESCRIPTION:
          'This is the currency in which your products are sold. After your first sale, the currency is locked and cannot be changed.',

        COUNTRY: 'Country'
      },
      LEGAL_PAGE: {
        PAGE_TITLE: 'Legal Page',
        PAYMENT_PROVIDERS: 'Legal Pages',
        PAYMENT_PROVIDERS_DESCRIPTION:
          'You can create your own legal pages. Your saved policies will be linked to the payment page, product detail page, and required screens. \n \n All responsibility for the texts contained herein belongs to the store owner.',
        DELIVERY_CONDITIONS: 'Delivery Conditions',
        TITLE: 'Title',
        CONTENT: 'Content',
        RETURN_AND_CANCELLATION_CONDITION: 'Return and Cancellation Conditions',
        DISTANCE_SELLING_CONTRACT: 'Distance Selling Contract',
        PAGE_URL: 'Page URL',
        SUGGEST_CONTENT: 'Suggest Content',
        ONLY_SUPPORT_TURKISH_AND_ENGLISH:
          'This feature only supports English & Turkish languages for now',
        FETCHING_CONTENT_SUGESSTION_FAILED:
          'Unfortunately, The fetching content suggestion failed!'
      },
      PAYMENT_PAGE: {
        CREDIT_CARD_PAYMENT_METHOD: 'Credit Card Payment Methods',
        PAGE_TITLE: 'Payment',
        PAYMENT_PROVIDERS: 'Payment Providers',
        PAYMENT_PROVIDERS_SUB_TITLE:
          'Accept payments. Build a strong payment infrastructure in your store using third-party providers or other payment methods.',
        PROVIDERS: 'Providers',
        PROVIDERS_SUB_TİTLE:
          'Strengthen your payment infrastructure with third-party payment providers. You can activate the payment provider you want to offer to your customers.',
        PAYPAL_DESCRIPTION:
          'Let customers use PayPal directly from the checkout page. You can receive payments in foreign currencies with PayPal.',
        IYZICO_DESCRIPTION:
          'Start receiving payments with iyzico Virtual POS, which allows you to make installments to all cards with a single agreement.',
        STRIPE_DESCRIPTION:
          'Let customers use Stripe directly from the checkout page. You can receive payments in foreign currencies with Stripe.',
        PAY_TR_DESCRIPTION:
          'Let customers use PayTr directly from the checkout page. You can receive payments in foreign currencies with PayTr.',
        BIRLESIK_ODEME_DESCRIPTION:
          'Let customers use united payment directly from the checkout page. You can receive payments in foreign currencies with united payment.',
        SHOPIROLLER_DESCRIPTION:
          'Let customers use Shopipay payment from the checkout page. You can receive payments in foreign currencies with our custom method.',
        PAYPAL_PROVIDER_CONFIGURE: 'Paypal Provider Configuration',
        STRIPE_PROVIDER_CONFIGURE: 'Stripe Provider Configuration',
        IYZICO_PROVIDER_CONFIGURE: 'Iyzico Provider Configuration',
        PAY_TR_PROVIDER_CONFIGURE: 'PayTR Provider Configuration',
        BIRLESIK_ODEME_PROVIDER_CONFIGURE:
          'United payment Provider Configuration',
        SHOPIPAY_PROVIDER_CONFIGURE: 'Shopipay Provider Configuration',
        CASH_ON_DELIVERY_CONFIGURE: 'Cash on Delivery Configuration',
        MONEY_ORDER_CONFIGURE: 'Money Order / EFT Configuration',
        CONFIGURE: 'Configure',
        MANUAL_PAYMENT_METHODS: 'Manual Payment Methods',
        MANUAL_PAYMENT_METHODS_DESCRIPTION:
          'Payments processed outside of your online store. When a customer makes a manual payment, you must confirm their order before submitting it.',
        CASH_ON_DELIVERY: 'Cash on Delivery',
        CASH_ON_DELIVERY_DESCRIPTION:
          'Not ready to accept online payments yet? Then it will be convenient for you to get paid by cash on delivery method.',
        MONEY_ORDER: 'Money Order/EFT',
        MONEY_ORDER_DESCRIPTION:
          'It will be a suitable payment method for customer groups who do not use credit/debit cards.',
        ACCESS_TOKEN: 'Access Token',
        PAYPAL_CONFIG_DESCRIPTION: 'Description',
        API_KEY: 'API Key',
        SECRET_KEY: 'Secret Key',
        MERCHANT_ID: 'Merchant Id',
        MERCHANT_KEY: 'Merchant Key',
        MERCHANT_SALT: 'Merchant Salt',
        HEAD: 'Head',
        RESELLER: 'Reseller',
        PAYMENT_AT_DOOR_STATEMENT: 'Description',
        CUSTOMERS_ARE_SHOWN_WHEN_CHOOSING_A_PAYMENT:
          'Customers are shown when choosing a payment method.',
        BANK_ACCOUNT: {
          TITLE: 'Bank Account',
          NEW_BANK_ACCOUNT: 'Add New Bank Account',
          BANK_NAME: 'Bank Name',
          ACCOUNT_NAME: 'Account Name',
          ACCOUNT_CODE: 'Account Code',
          ACCOUNT_HOLDER: 'Account Holder/Name Surname',
          ACCOUNT_NO: 'Account No'
        },
        PERSONAL: 'Personal',
        PERSONAL_COMPANY: 'Personal Company',
        COMPANY: 'Company',
        ACCOUNT_TYPE: 'Account Type',
        TAX_NUMBER: 'Tax Number',
        TAX_OFFICE: 'Tax Office',
        BANK_IBAN: 'IBAN',
        BANK_NAME: 'Bank Name',
        COMPANY_NAME: 'Company Name',
        OWNER_NAME: "Store Owner's Name",
        OWNER_SURNAME: "Store Owner's Surname",
        ACCOUNT_HOLDER_NAME: "Account Holder's Name and Surname",
        OWNER_IDENTITY_NUMBER: "Store Qwner's Identity Number",
        GSM_PHONE_NUMBER: 'GSM(Phone Number)',
        SUCCESSFUL_UPDATE_NOT_CONFIRMED:
          'Your request has been received. After your request is approved, a notification e-mail will be sent to you.',
        SUCCESSFUL_UPDATE_CONFIRMED:
          'Your payment method changes has been successfully saved.',
        BIRTH_DATE: 'Birth Date',
        WHICH_PRODUCTS_YOU_ARE_SELLING: "Which products you're selling?",
        WHICH_PLATFORM_YOU_ARE_SELLING_ON: 'Which platform you are selling on?',
        OTHER_SELLING_PLATFORM_DESCRIPTION:
          'Please provide information about your sales platform/models',
        SOCIAL_MEDIA: 'Social Media(Instagram, Facebook, etc)',
        MY_WEBSITE: 'My Website',
        MARKETPLACE: 'Marketplace',
        OTHER: 'Other',
        IDENTITY_NUMBER_VALIDATION_ERROR:
          'The identity Number length must be between {{minLength}} and {{maxLength}} numbers',
        IBAN_VALIDATION_ERROR:
          'The Iban length must be between {{minLength}} and {{maxLength}} numbers',
        SHOPIPAY_SALE_POLICY_WARNING:
          "Please be careful about your configuration, it's only possible to configure Shopipay for one time due to the sales policies",
        SHOPIPAY_PASSIVE_MESSAGE: 'ShopiPay has been disabled',
        ACCOUNT_NAME: 'Account Name'
      },
      SHIPPING: {
        PAGE_TITLE: 'Shipping and Delivery',
        SHIPPING_SETTINGS: 'Shipping Settings',
        SHIPPING_SETTINGS_DESCRIPTION:
          "Manage your shipping and delivery options. Don't worry, you can manage product-specific shipping settings from product details.",
        SYSTEM_FIXED_SHIPPING_FEE: 'System Fixed Shipping Fee',
        ENABLE_FREE_SHIPPING_THRESHOLD: 'Enable Free Shipping Threshold',
        ENABLE_FREE_SHIPPING_THRESHOLD_DESCRIPTION:
          'If you enable the free shipping threshold, shipping will be free when the customer cart reaches the amount you set.',
        FREE_SHIPPING_THRESHOLD: 'Free Shipping Threshold',
        DOMESTIC_SHIPPING_FOR_TURKEY: 'Domestic shipping for Turkey',
        DOMESTIC_SHIPPING_FOR_TURKEY_CONFIGURATION:
          'Domestic shipping Configuration',
        DOMESTIC_SHIPPING_FOR_TURKEY_CONFIGURATION_WARNING:
          "Please be careful about your configuration, it's only possible to configure domestic shipping for one time due to the policies",
        CARGO_INTEGRATION_DESCRIPTION:
          "Shopiroller now offers post-sale support, including delivery and logistics assistance. We've partnered with Türkiye's cargo companies, offering great prices for domestic shipments. And soon, we'll integrate international shipments too!",
        SUCCESSFULL_CARGO_CONFIGURATION_UPDATE:
          'Congratulations, your application has been evaluated. You will receive an email when approved',
        FAILED_CARGO_CONFIGURATION_UPDATE:
          "Unfortunately, we couldn't set your shipping configuration"
      },
      SLIDER: {
        PAGE_TITLE: 'Slide Management',
        SLIDER: 'Slider',
        SLIDER_SUB_TITLE:
          'You can create various sliders to use in your application. You can direct the sliders you create to a category, product or any web address.',
        NO_SLIDER:
          'Your application has no slider yet, please add one slider from your setting panel',
        ADD_NEW_SLIDE: 'Add New Slide',
        NEW_SLIDE: {
          TITLE: 'New Slide',
          NAVIGATION: 'Navigation',
          TARGET: 'Target',
          IMAGE_UPLOAD: 'Image Upload',
          UPLOAD_MSG: 'A suitable image upload library can be chosen.',
          ADD_SLIDE: 'Add Slide'
        },
        EDIT_SLIDE: 'Edit Slide',
        NO_SLIDE: "Oh, You haven't added any slides. \n Add your first slide.",
        ADD_FIRST_SLIDE: 'Add your first slide.',
        SLIDE: 'Slide'
      },
      EMAIL_NOTIFICATION: {
        PAGE_TITLE: 'E-mail Notification',
        EMAIL_SENDING_OPTIONS: 'Email sending options',
        EMAIL_SENDING_OPTIONS_DESCRIPTION:
          ' You can set a default e-mail address for notifications sent to your customers. If you leave it blank, it will be sent with a default address.',
        SENDER_EMAIL_ADDRESS: 'Sender Email Address',
        ADD_NEW_RECIPIENT: 'Add New Recipient',
        SYSTEM_NOTIFICATION: 'Staff System Notifications',
        SYSTEM_NOTIFICATION_DESCRIPTION:
          ' New order notifications or other system notifications are automatically sent to the recipients you specify.',
        SEND_TO: 'Send to',
        RECIPIENT: 'Recipient',
        NEW_RECIPIENT: 'New Recipient',
        NEW_RECIPIENT_ERROR:
          'Sorry! We can not fulfill your request. There must always be a valid e-mail address here',
        RECIPIENT_EMAIL_ADDRESS: 'Recipient Email Address',
        SEPARATE_EMAIL:
          'To enter more than one address, you can separate them with a comma (,)',
        SENDER_EMAIL_EDITED_SUCCESSFULLY: 'Sender email edited successfully'
      },
      MESSAGES: {
        STORE_SUCCESSFULLY_EDITED: 'Store successfully edited',
        STORE_FAILED_EDITED: 'Store failed edited',
        ILLEGAL_DELETE_EMAIL: 'There must always be an email address here',
        EMAILS_NOTIFICATION_SUCCESSFULLY_EDITED:
          'Email notifications successfully edited',
        EMAILS_NOTIFICATION_SUCCESSFULLY_DELETED:
          'Email notifications successfully deleted',
        EMAILS_NOTIFICATION_FAILED_EDITED: 'Email notifications failed edited',
        EMAILS_NOTIFICATION_FAILED_DELETED:
          'Email notifications failed deleted',
        CURRENCY_CANT_UPDATE:
          'You cannot change the currency after receiving an order'
      },
      VALIDATIONS: {
        NAME_REQUIRED: 'Name is required',
        SECTOR_REQUIRED: 'Sector is required',
        EMAIL_REQUIRED: 'Email is required',
        CURRENCY_REQUIRED: 'Currency is required',
        CATEGORY_IS_REQUIRED: 'Category is required',
        PRODUCT_ID_IS_REQUIRED: 'Product ID is required',
        WEB_URL_IS_REQUIRED: 'Web URL is required',
        IMAGE_NOT_UPLOADED: 'Image not uploaded',
        IMAGE_MAX_SIZE: 'The maximum file size should be 4 MB',
        IMAGE_VALID_TYPE: 'Valid type for the image are PNG,JPG,JPEG'
      }
    },
    PRODUCTS: {
      productStatusChanged: '{{case}} status changed successfully',
      SUCCESSFUL_COPY: 'Product copy operation completed',
      SLUG_ALREADY_EXIST: 'Slug already exists, you need to change it',
      SEO_OPTIONS: 'SEO Options',
      SLUG: 'Slug',
      PAGE_TITLE: 'Page Title',
      MIN_STOCK: 'Min stock',
      MAX_STOCK: 'Max stock',
      MIN_STOCK_ERROR:
        'The minimum stock number must be less than or equal Maximum stock number',
      VIDEO_URL: 'Video URL',
      ATTACH_A_VIDEO_TO_PRODUCT: 'For Attaching a video click',
      UPGRADE_TO_ATTACH_A_VIDEO_TO_PRODUCT:
        'For Attaching a video you need to upgrade your subscription',
      ADD_FILE_FROM_URL: "Add file from URL's",
      EDIT_PRODUCT_VIDEO_URL: 'Edit your product video URL',
      TITLE: 'Products',
      PRODUCT: 'Product',
      DESCRIPTION: 'Description',
      CATEGORY: 'Category',
      NEW_PRODUCT: 'New Product',
      PRODUCT_NAME: 'Product Name',
      BRAND: 'Brand',
      STATUS: 'Status',
      ACTIVE: 'Active',
      PASSIVE: 'Passive',
      PLANNED: 'Planned',
      PUBLISHING_DATE: 'Publishing Date',
      PRICING: 'Pricing',
      PRODUCT_PRICE: 'Product Price',
      DISCOUNT_PRODUCT_PRICE: 'Discounted Product Price',
      STOCK: 'Stock',
      STOCK_CODE: 'Stock Code',
      NUMBER_OF_STUCK: 'Number Of Stock',
      MAXIMUM_SALE: 'Maximum Sales',
      SHIPPING: 'Shipping',
      FREE_SHIPPING: 'Free Shipping',
      FREE_SHIPPING_DESCRIPTION:
        'You can select the "Free Shipping" label to appear on the product.',
      FIXED_FREE_SHIPPING: 'Fixed Shipping Fee',
      FIXED_FREE_SHIPPING_DESCRIPTION:
        'Shipment is made with the shipping fee you entered on the store settings screen.',
      CUSTOM_SHIPPING_PRICE: 'Custom shipping Price',
      CUSTOM_SHIPPING_PRICE_DESCRIPTION:
        'If you have a special shipping fee for the product, you can choose this option.',
      MEDIA: 'Media',
      PRODUCT_DELETE: 'Product Delete',
      SHOWCASE: 'Showcase',
      DISCOUNTED_PRODUCT_PRICE_ERROR:
        "Discounted product price must be less than '{{field}}'",
      DISCOUNTED_PRODUCT_PRICE_ERROR_FOR_VARIANT:
        "Discounted product price must be less than '{{field}}' in {{productName}}",
      PRODUCT_PRICE_SHOULD_BE_GREATER_THAN_0:
        "'Price' must be greater than '0'.",
      PRODUCT_PRICE_SHOULD_BE_GREATER_THAN_0_FOR_VARIANT:
        "'Price' must be greater than '0' in {{productName}} .",
      DELETE_PRODUCT: 'Delete Product',
      STOCK_CODE_SKU: 'Stock Code (SKU)',
      PRICE: 'Price',
      PRODUCT_TITLE_A_Z: 'Product title A–Z',
      PRODUCT_TITLE_Z_A: 'Product title Z–A',
      CREATION_OLDEST_FIRST: 'Creation (oldest first)',
      CREATION_NEWEST_FIRST: 'Creation (newest first)',
      PRICE_INCREASING: 'Price Increasing',
      PRICE_DECREASING: 'Price Descending',
      BRAND_TITLE_A_Z: 'Brand title A–Z',
      BRAND_TITLE_Z_A: 'Brand title Z–A',
      CATEGORY_TITLE_A_Z: 'Category title A–Z',
      CATEGORY_TITLE_Z_A: 'Category title Z–A',
      PRODUCT_SEARCH: 'Search ...',
      EDIT_PRODUCT: 'Edit product',
      DOWNLOAD_SAMPLE_IMPORT_FILE:
        "Download a <a href='../../../../../assets/shopiroller-import.xlsx'><u>sample xlsx template</u></a> to see an example of the format required",
      EXPORT_PROGRESS_STARTED_MESSAGE:
        'The export process has been started. We will notify you by E-mail when it is finished. Please check your E-mail',
      IMPORT_EMPTY_FILE_ERROR: "The uploaded file can't be empty",
      IMPORT_PRODUCTS_BY_XLSX: 'Import products by xlsx',
      IMPORT_INVALID_FILE_TYPE_MESSAGE: 'Only XLSX files are acceptable',
      SUCCESSFUL_IMPORT:
        'The import process just started and probably take a long time. When it gets completed, an email will be sent to notify you',
      EXPORT_NO_PRODUCTS_ERROR:
        'There is no Product in your project for export',
      IMPORT_SUCCESSFUL: 'The import operation succeed.',
      IMPORT_FAILED: 'The import operation failed.',
      IMPORT_PROGRESS_STARTED_MESSAGE:
        'The import process has been started. Please check your e-mail because we will notify you when the import process is finished.',
      PRODUCT_LIST: {
        TITLE: 'Product List',
        ADD_NEW_PRODUCT: 'Add New Product',
        PRODUCT_SEARCH: 'Product Search',
        DELETE_PRODUCT: {
          TITLE: 'Delete Product',
          MESSAGE:
            "Are you sure you want to delete '{{productTitle}}' permanently? This action cannot be undone!",
          TITLE_SOME: 'Delete Product',
          MESSAGE_SOME:
            'Are you sure you want to delete selected products permanently? This action cannot be undone!'
        },
        BUTTONS: {
          ADD: 'Add Product',
          EDIT: 'Edit Product',
          DELETE: 'Delete Product',
          DELETE_SOME: 'Delete Products'
        },
        NO_PRODUCT_MESSAGE:
          'Upload Your First Product! Upload your products and start selling right away.'
      },
      BRANDS: {
        BRAND: 'Brand',
        BRANDS: 'Brands',
        TITLE_A_Z: 'Brand Title A-Z',
        TITLE_Z_A: 'Brand Title Z-A',
        CREATED_OLDEST: 'Created (oldest first)',
        CREATED_NEWEST: 'Created (newest first)',
        BRAND_SEARCH: 'Search ...',
        LIST: 'Brands List',
        ADD_NEW_BRAND: 'Add New Brand',
        NEW_BRAND: 'New Brand',
        EDIT_BRAND: 'Edit Brand',
        DELETE_BRAND: 'Delete Brand',
        NAME: 'Brand Name',
        STATUS: 'Status',
        IMAGE_UPLOAD: 'Image Upload',
        CONFIRM_MSG_FOR_SOME_BRAND:
          "Delete 'brands'? This action cannot be undone.",
        CONFIRM_MSG_FOR_A_BRAND:
          "Delete '{{brandName}}'? This action cannot be undone.",
        BRAND_CANT_BE_ACTIVATED: 'This Brand is already active',
        BRAND_CANT_BE_DEACTIVATED: 'This Brand is already passive',
        TOTAL_BRAND_LIMIT_OVERFLOW:
          'You already reached the maximum number of possible brands in the application',
        PASSIVE_BRANDS_ARE_FORBIDDEN_FOR_DELETE:
          'Passive brands are not allowed for delete',
        CONFIRM_DIALOG: {
          TITLE: 'Delete Brand',
          MESSAGE:
            "Are you sure you want to delete '{{brandName}}' permanently? This action cannot be undone!",
          TITLE_SOME: 'Delete Brand',
          MESSAGE_SOME:
            'Are you sure you want to delete selected brands permanently? This action cannot be undone!'
        },
        BUTTONS: {
          ADD: 'Add Brand',
          EDIT: 'Edit Brand',
          DELETE: 'Delete Brand'
        },
        NO_BRAND_MESSAGE:
          'Add Brand! Group your products into brands in your store.'
      },
      CATEGORIES: {
        PAGE_TITLE: 'Categories',
        CATEGORY: 'Category',
        LIST: 'Categories List',
        NEW_CATEGORY: 'New Category',
        EDIT_CATEGORY: 'Edit Category',
        DELETE_CATEGORY: 'Delete Category',
        SUB_CATEGORIES: 'Sub Categories',
        CATEGORY_NAME: 'Category Name',
        PARENT_CATEGORY: 'Parent Category',
        SELECT_PARENT_CATEGORY: 'Select parent category',
        CATEGORY_CANT_BE_DELETED:
          'Cant delete category which has product in it',
        CATEGORY_NOT_FOUND: 'Category Not Found',
        DEFAULT_LANGUAGE_NOT_SET: 'The default language is not set',
        ADD_NEW_CATEGORIES: 'Add New Categories',
        SEARCH: 'Search...',
        ALL_NAMES: 'All  names of category',
        MAXIMUM_NESTED_LEVEL_EXCEEDED:
          'Maximum nested level for subcategories exceeded',
        SAME_CATEGORY_AND_PARENT_ID:
          'CategoryId and ParentCategoryId cannot be the same!',
        DELETE: {
          TITLE: 'Delete Category',
          MESSAGE:
            "Are you sure you want to delete '{{categoryName}}' permanently? This action cannot be undone!",
          TITLE_SOME: 'Delete Category',
          MESSAGE_SOME:
            'Are you sure you want to delete selected categories permanently? This action cannot be undone!'
        },
        BUTTONS: {
          ADD: 'Add Category',
          EDIT: 'Edit Category',
          DELETE: 'Delete Category',
          DELETE_SOME: 'Delete Categories'
        },
        NO_CATEGORY_MESSAGE:
          'Add Category! Group your products into categories in your store.'
      },
      VARIANTS: {
        SEE_ALL_VARIANTS:
          'There are more than 4 values for this variant, click on the edit button and see all of them',
        TITLE: 'Variant Type',
        VARIANT_LIST: 'Variant Type List',
        VARIANT_VALUES: 'Variant Values',
        ADD_NEW_VARIANT: 'Add New Variant Type',
        NEW_VARIANT: 'New Variant',
        EDIT_VARIANT: 'Edit Variant Type',
        VARIANT_NAME: 'Variant Type Name',
        ADD_VARIANT: 'Add Variant Type',
        DELETE_VARIANT: 'Delete Variant Type',
        VARIANT_SEARCH: 'Search...',
        TITLE_A_Z: 'Variant Title A-Z',
        TITLE_Z_A: 'Variant Title Z-A',
        CREATED_OLDEST: 'Created (oldest first)',
        CREATED_NEWEST: 'Created (newest first)',
        NO_VARIANT_MESSAGE:
          'Add Variant Type! Add variant types to your products such as color, size and display them in your store.',
        NO_VARIANT_IN_PRODUCT:
          "You don't have any variant for this product yet",
        DELETE_TITLE_SINGLE: 'Deleted Variant Type',
        DELETE_MESSAGE_SINGLE:
          "Delete '{{variantName}}'? This action cannot be undone.",
        DELETE_TITLE_SOME: 'Deleted Variant Type',
        DELETE_MESSAGE_SOME: "Delete 'variants'? This action cannot be undone.",
        DELETE_VARIANT_VALUE_TITLE: 'Variant value will be Deleted',
        NO_VARIANT_VALUE_ERROR: 'There is no value for the selected variant',
        REPETITIVE_VARIANT_ERROR: 'You selected this variant previously!',
        VARIANT_ERROR:
          'The change cannot be made because the variant group is added to the product',
        VARIATIONS_EXCEEDED:
          'The operation failed, you are only allowed to add 10 variant values maximally',
        VARIANT_EXAMPLE: 'e.g S, M, L, XL, XXL',
        MAXIMUM_VARIANT_TOOLTIP: 'You can add maximum 3 variant',
        PRODUCT_ADD_MAXIMUM_VARIANT_EXCEEDED:
          'Product adding failed ! You can add a maximum of 3 variants for each product.',
        PRODUCT_UPDATE_MAXIMUM_VARIANT_EXCEEDED:
          'Product editing failed ! You can add a maximum of 3 variants for each product.',
        VARIATION_GROUP_CANT_BE_DELETED:
          'This variation group is used for a product and can not be deleted!',
        VARIANT_IMAGE_UPLOADER_HEADER:
          'You can only choose an image as variant media',
        SELECT_VARIANT_IMAGE: 'Select variant image',
        UPDATE_VARIANT_IMAGE: 'Update variant image'
      },
      SHOWCASES: {
        TITLE: 'Showcase',
        SHOWCASES: 'Showcases',
        ADD_NEW_SHOWCASE: 'Add New Showcase',
        EDIT_SHOWCASE: 'Edit Showcase',
        TITLE_A_Z: 'Showcase title A–Z',
        TITLE_Z_A: 'Showcase title Z–A',
        SEARCH: 'Search...',
        DELETE: 'Delete Showcases',
        DELETE_SHOWCASE: {
          TITLE: 'Deleted Showcase',
          MESSAGE:
            "Are you sure you want to delete '{{showcaseName}}' permanently? This action cannot be undone!",
          TITLE_SOME: 'Deleted Showcase',
          MESSAGE_SOME:
            'Are you sure you want to delete selected showcases permanently? This action cannot be undone!'
        },
        SHOWCASE_LIST: 'Showcase List',
        SHOWCASE_NAME: 'Showcase Name',
        NEW_SHOWCASE: 'New Showcase',
        ADD_PRODUCT: 'Add Product',
        ADD_SELECTED_PRODUCTS: 'Add Selected Products',
        NO_PRODUCT_SELECTED: 'No product has been selected yet.',
        OUT_OF_SHOWCASES_ERROR:
          'You are not allowed to add more than 50 showcases',
        BUTTONS: {
          ADD: 'Add Showcase',
          EDIT: 'Edit Showcase',
          DELETE: 'Delete Showcase',
          DELETE_SOME: 'Delete Showcases'
        },
        NO_SHOWCASE_MESSAGE:
          'Add Showcase! Use showcases to group your products in your store.'
      }
    },
    CLIENTS: {
      ORDERS_COUNT: "Shows the client's order count",
      CLIENTS: 'Clients',
      CLIENT_LIST: 'Client List',
      CLIENT_SEARCH: 'Clients search...',
      E_MAIL: 'E-Mail',
      E_MAIL_ADDRESS: 'E-mail Address',
      REGISTRATION_DATE: 'Registration Date',
      ORDER: 'Order',
      SPENT: 'Spent',
      CLIENT_DETAIL: 'Client Detail',
      DELETE_CLIENT: 'Delete Client',
      NO_CLIENT_MESSAGE: 'Sorry, there are no client',
      CLIENT_NAME_A_Z: 'Client Name A-Z',
      CLIENT_NAME_Z_A: 'Client Name Z-A',
      REGISTRATION_DATE_A_Z: 'Registration Date (oldest first)',
      REGISTRATION_DATE_Z_A: 'Registration Date (newest first)',
      CLIENT_NAME: 'Client Name',
      MAKE_PASSIVE: 'Make Passive',
      MAKE_ACTIVE: 'Make Active',
      TOTAL_EXPENDITURE: 'Total Expenditure',
      AVERAGE_ORDER_VALUE: 'Average Order Value',
      LAST_ORDER: 'Last Order',
      USER_ORDERS_COUNT:
        'There are {{orderCount}} orders belonging to the customer',
      USER_REGISTRATION_DATE: 'The user is registered in the system',
      USER_FIRST_ORDER:
        'User created his first order. Amount {{firstOrderAmount}}',
      USER_ORDER: 'User created an order. Amount {{firstOrderAmount}}'
    },
    ORDERS: {
      CARGOR_OFFER_WARNING:
        'Prices do not include taxes. Taxes will be included and deducted from your collection when your order is delivered',
      OFFER_ACCEPTED_SUCCESSFULLY:
        'Congratulation, you are offered successfully',
      ACCEPT_OFFER_FAILED: "Unfortunately, your offer didn't accept",
      GET_AN_OFFER: 'Get an offer',
      SHIPMENT_PRICE: 'Shipment Price',
      ORDER_PRICE_AMOUNT: 'Order Amount',
      CARGO_OFFERS: 'Cargo offers',
      FASTEST: 'Fastest',
      CHEAPEST: 'Cheapest',
      CARGO_DIMENSION: 'Cargo Dimension',
      CARGO: 'Cargo',
      GET_CARGO_OFFER: 'Get Cargo Offer',
      NO_PAYMENT_DATA: 'There is no payment data for this order!',
      ORDERS: 'Orders',
      ORDER_LIST: 'Order List',
      ORDER_DETAIL: 'Order Detail',
      REFUND: 'Refund',
      ORDER: 'Order',
      DATE: 'Date',
      CUSTOMER: 'Customer',
      PAYMENT_TYPE: 'Payment Type',
      AMOUNT_PAID: 'Amount Paid',
      ORDER_SEARCH: 'Search...',
      ORDER_NUMBER: 'Order Number',
      ORDER_AMOUNT: 'Total Amount',
      PAYMENT_SUCCESSFUL: 'Payment Successful',
      ORDER_FEE: 'Profit',
      ORDER_INSTALLMENT: 'Installment',
      ORDER_CAN_SEND_TRANSFER: 'Can send transfer',
      COLLECTION_STATUS: 'Collection Status',
      ORDER_FEE_DESCRIPTION:
        'This is the amount you can collect for this order. In addition, all commission rates are refunded for refunds or cancellations',
      COMMISSION_RATE: 'Commission Rate',
      COMMISSION_AMOUNT: 'Commission Amount',
      DATE_OLDEST_FIRST: 'Date (oldest first)',
      DATE_NEWEST_FIRST: 'Date (newest first)',
      PRICE_ASCENDING: 'Price Ascending',
      PRICE_DESCENDING: 'Price Descending',
      ORDER_DATE: 'Order Date',
      NO_ORDER_MESSAGE:
        'Manage Your Orders! Submit orders, check payments and status of orders.',
      STATUS_TRACKING: 'Status & Tracking',
      NOTIFICATION: 'Notification',
      ORDER_STATUS: 'Order Status',
      NOTIFY_USER_OF_CHANGES: 'Notify the user of changes by email',
      SHIPPING_TRACKING_CODE: 'Shipping Tracking Code',
      SHIPPING_COMPANY: 'Shipping Company',
      PAYMENT_INFORMATION: 'Payment Information',
      CARGO_INFORMATION: 'Cargo Information',
      CARGO_TRACKING_LINK: 'Cargo Tracking Link',
      CURRENT_AMOUNT_OF_CARGO: 'Current amount of cargo',
      CARGO_BARCODE: 'Cargo Barcode',
      DECLARE_ORDER_CODE_TOCARGO_PERSONEL:
        'After you have declared that you will make transactions through the Geliver Bilişim A.Ş contract on the Marketplace integration screen, Please announce the order code to the Cargo personnel',
      PAYMENT_METHOD: 'Payment Method',
      PAYMENT_DETAILS: 'Payment Details',
      SUB_TOTAL: 'Subtotal',
      SHIPMENT: 'Shipment',
      TOTAL: 'Total',
      MAKE_A_REFUND: 'Make a Refund',
      NOTES: 'Notes',
      NO_NOTES: 'No clients notes',
      PRODUCTS: 'Products',
      TIMELINE: 'Timeline',
      TIMELINE_DESCRIPTION: 'The order has been',
      BILLING_INFORMATION: 'Billing Information',
      DELIVERY_INFORMATION: 'Delivery Information',
      CLIENT: 'Client',
      STOCK_CODE: 'Stock Code',
      REFUND_REASON: 'Refund Reason',
      REASON_FOR_RETURN: 'Reason for Return',
      DESCRIPTION: 'Description (Optional)',
      CAUTION: 'Only you and other staff can see this',
      SUMMARY: 'Summary',
      REFUND_TOTAL: 'Refund Total',
      BUYER_REQUEST: 'Buyer Request',
      DOUBLE_PAYMENT: 'Double Payment',
      FRAUD: 'Fraud',
      OTHER: 'Other',
      ALREADY_REFUNDED: 'One of the products has already refunded',
      REFUND_WARNING: 'You should choose the products that you want to refund',
      TOTAL_AMOUNT: 'Total Amount',
      WAITING_PAYMENT: 'Waiting for payment',
      WAITING_APPROVAL: 'Waiting for approval',
      APPROVED: 'Approved',
      WAITING_FOR_SUPPLYING: 'Waiting for supplying',
      PREPARING: 'Preparing',
      SHIPPED: 'Shipped',
      DELIVERED: 'Delivered',
      CANCEL_REQUESTED: 'Cancel requested',
      CANCELED: 'Canceled',
      REFUNDED: 'Refunded',
      PAYMENT_FAILED: 'failed',
      COMPLETED: 'Completed',
      COMPLETE_ORDER: 'Complete Order',
      CANT_CHANGE_ORDER_STATUS:
        'If the status of the order is refunded, completed, or canceled, the order status cannot be changed',
      CANT_CHANGE_ORDER_STATUS_WARNING:
        "If you change the status of the order to refunded, completed, or canceled, the order status won't be changed",
      SHOPIROLLER_COLLECT_ORDER_MESSAGE:
        'Your order cannot be processed for collection unless it is marked as complete. Make sure to complete your orders by 8:00 PM every Wednesday, at which point your payment for the order will be credited to your account. Please remember, it is essential to provide the shipment tracking number and the shipping company details to complete your orders',
      ORDER_PAYMENT_STATUS: 'Order Payment Status',
      TOTAL_PRICE: 'Total Price',
      NO_SHOPIPAY_MESSAGE: 'There is no collection record',
      SHOPIPAY_PAGE_MESSAGE:
        ' This page contains detailed information for the orders you receive payment via Shopipay. Your orders marked as Completed every Wednesday at 20:00 are sent to your Iban address.',
      TOTAL_AMOUNT_TO_BE_CHARGE: 'Total amount to be charged',
      COMPLETED_ORDER_STATUS_DESCRIPTION: 'The order has been completed',
      PAYMENT_FAILED_ORDER_STATUS_DESCRIPTION:
        'The payment for the order has failed',
      REFUNDED_ORDER_STATUS_DESCRIPTION: 'The order has been refunded',
      CANCELED_ORDER_STATUS_DESCRIPTION: 'The order has been cancelled',
      CANCEL_REQUESTED_ORDER_STATUS_DESCRIPTION:
        'A cancellation request has been submitted for the order',
      DELIVERED_ORDER_STATUS_DESCRIPTION: 'The order has been delivered',
      SHIPPED_ORDER_STATUS_DESCRIPTION: 'The order has been shipped',
      PREPARING_ORDER_STATUS_DESCRIPTION: 'The order is waiting to be supplied',
      WAITING_FOR_SUPPLYING_ORDER_STATUS_DESCRIPTION:
        'The order is waiting to be supplied',
      APPROVED_ORDER_STATUS_DESCRIPTION: 'The order has been approved',
      WAITING_APPROVAL_ORDER_STATUS_DESCRIPTION:
        'The order is waiting for approval',
      WAITING_PAYMENT_ORDER_STATUS_DESCRIPTION:
        'The order is waiting for payment'
    },
    STATISTICS: {
      STATISTICS: 'Statistic',
      RECENT_ORDERS: 'Recent Orders',
      ORDERS_COUNT_REPORT: 'More than {{items}}+ new orders',
      TOTAL_ORDER: 'Total Orders',
      ORDERS_COUNT: 'Orders count',
      ORDERS_TOTAL_PRICE: 'Orders total price',
      ORDERS_TOTAL_COUNT: 'Orders total count',
      TOTAL_PRICE: 'Total price',
      MOST_ORDERED_PRODUCTS: 'Most Ordered Products',
      PAYMENT_METHODS: 'Payment methods',
      DATE_RANGE_ERROR: 'You must select a date boundary',
      DATE_RANGE_END_DATE_ERROR: 'You must select an end date for date range'
    },
    GENERAL_SETTINGS: {
      STORE_NAME: 'Store Name',
      STORE_INFORMATION: 'Store Information',
      STORE_SETTINGS: 'Store Settings',
      DELETE_STORE: 'Delete Store',
      TITLE: 'General Settings',
      PROJECT_SETTINGS_TITLE: 'Project Settings',
      PROJECT_NAME: 'Project Name',
      ADVANCED_INFORMATION: 'Advanced Information',
      VALUE: 'Value',
      COPY: 'Copy',
      PACKAGE_NAME: 'Package Name',
      DELETE_PROJECT: 'Delete Project',
      DELETE_DESCRIPTION:
        'Your project will be permanently deleted. This action cannot be undone.',
      DELETE_STORE_DESCRIPTION:
        'Your store will be permanently deleted. This action cannot be undone.',
      DELETE_STORE_PRIMARY_DATA:
        'Delete primary data that was added to your store in the creation process',
      DELETE_DEMO_DATA: 'Delete Demo Data',
      DEMO_DATA_DELETED: 'Your store demo data was deleted successfully',
      LANGUAGE_REGION: 'Language & Region',
      LANGUAGE_LOCALIZATION: 'Language & Localization',
      ALL_LANGUAGE: 'All Languages',
      ADD_LANGUAGE: 'Add Language',
      ADD_LANGUAGES: 'Add Languages',
      EDIT_LANGUAGE: 'Edit Language',
      CODE: 'Code',
      LANGUAGE: 'Language',
      APP_ID: 'APP ID',
      APP_KEY: 'APP Key',
      API_KEY: 'API Key',
      CHANGE_DEFAULT_LANGUAGE:
        'Set this language as the default language of the current app',
      SELECT_LANGUAGE: 'Select Language',
      DEFAULT_LANGUAGE_DELETE_ERROR:
        "You can't delete the default language of an app",
      SEARCH_FOR_ADD: 'Search for adding new language',
      TYPE_PROJECT_NAME:
        'Please type in the name of the project to confirm its deletion.',
      PERMANENT_DELETE_PROJECT: 'It will permanently delete project',
      ALONG_WITH_EVERTING: ', along with all its strings and contributors.',
      PROJECT_NAME_IS_INVALID:
        'Entered value for the project name is not the same with the original name.',
      COPIED_TO_CLIPBOARD: '✔ copied to clipboard',
      CHANGE_DEFAULT_LANGUAGE_ERROR:
        'You cannot change the default language while you have a content',
      SUPPORT_ACCESS_PERMISSION_TITLE:
        'Your project will be accessible by the shopiroller support team and there will be a chance to make some operations on it for them.',
      SUPPORT_ACCESS_PERMISSION: 'Give Access',
      CANCEL_ACCESS_PERMISSION: 'Cancel Permission',
      ALLOW_SUPPORT_TEAM_ACCESS_PERMISSION_TITLE:
        "You didn't give the permission to shopiroller support team, if you want they access to your project please click",
      DONT_ALLOW_SUPPORT_TEAM_ACCESS_PERMISSION_TITLE:
        'You already gave the access permission, if you want to cancel it please click',
      PERMISSION_GRANTED:
        'Permission granted to the shopiroller support team successfully',
      PERMISSION_ABOLISHED:
        'Permission is cancelled for the shopiroller support team',
      PERMISSION_STATUS_CHANGE_FAILED:
        "Unfortunately, Access permission couldn't change due to an unknown reason"
    },
    USER_PROFILE: {
      MY_ACCOUNT: 'My Account',
      ACCOUNT_INFORMATION: 'Account Information',
      PASSWORD_CHANGE: 'Password Change',
      CLOSE_ACCOUNT: 'Close Account',
      ACCOUNT_CLOSURE: 'Account Closure',
      PREFERRED_LANGUAGE: 'Preferred language',
      PREFERRED_LANGUAGE_ALERT:
        "When you're logged in to Shopiroller, this is the language you will see. It doesn't affect the language your customers see on your online store.",
      EMAIL_CHANGE_FORBIDDEN: 'You cannot change your e-mail address.',
      CURRENT_PASSWORD: 'Current Password',
      NEW_PASSWORD: 'New Password',
      CONFIRM_NEW_PASSWORD: 'Confirm New Password',
      CHANGE_PASSWORD: 'Change Password',
      PASSWORD_TIPS: 'Secure password tips',
      PASSWORD_MIN_LENGTH:
        'Your password should be at least 8 characters. Combination of numbers, letters and special characters is best.',
      REUSE_WARNING:
        'Do not reuse the password you used in Shopiroller before.',
      GENERAL_WARNING:
        'Do not use your name, e-mail address, mobile phone number or other easily obtainable personal information.',
      SAME_PASSWORD_WARNING:
        'Do not use the same password for multiple online accounts.',
      UPDATE_PASSWORD_WARNING:
        'You must update your password every three months.',
      CLOSE_ACCOUNT_SORRY_MESSAGE:
        'We are sorry that you want to close your Shopiroller account. Please indicate the reason for closing your account so that we can receive your feedback.',
      CLOSE_MY_ACCOUNT: 'Close My Account',
      ACCOUNT_IRREVERSIBLE_WARNING:
        "Account closure is irreversible, even if you accidentally close your account, it cannot be undone. If you are confirming the closure of your account, please enter your Shopiroller password and click the 'CLOSE MY ACCOUNT' button..",
      LOSE_ACCESS_TO_PROJECT_WARNING:
        'After you close your account, all your {{projectCount}} projects will be unsubscribed and you will lose access to them completely.',
      CLOSE_ACCOUNT_AFTER_APPROVAL_WARNING:
        'After your approval, your Shopiroller account will be closed.',
      OTHER: 'Other (specify)',
      I_STOPPED_SELLING_ONLINE: 'I stopped selling online',
      I_WILL_USE_DIFFERENT_SOLUTION:
        'I will use a different solution from Shopiroller',
      I_WILL_USE_DIFFERENT_ACCOUNT:
        'I will use a different Shopiroller account',
      SPECIFY_REASON: 'Specify the reason for account closure',
      MOBILE_VERIFICATION: 'Mobile Verification',
      YOUR_PHONE_NUMBER: 'Your Phone Number',
      VERIFICATION_CODE: 'Verification Code',
      ENTER_VERIFICATION_CODE: 'Enter the verification code sent to your phone',
      VERIFY: 'Verify',
      CONFIRM_PASSWORD_DOES_NOT_MATCH_PASSWORD:
        "Confirm password and password don't match",
      PHONE_MASK_IS_INVALID:
        'The phone is not valid, please fill in all numbers',
      CLOSE_ACCOUNT_SUCCEEDED:
        'Your account closed successfully, you will redirect to the login page after some seconds.',
      SELECT_ONE_OF_REASON_ERROR: 'You must select one of the reason',
      PASSWORD_MIS_MATCH: 'Old password is wrong. Please check again !',
      CHECK_PASSWORD_ERROR:
        'Your request has failed, please make sure you are entering the correct password',
      VERIFICATION_CODE_SENT: 'Verification code sent to your phone number',
      WRONG_VERIFICATION_CODE_ERROR:
        'Verification failed, please make sure you entered the correct code'
    },
    DASHBOARD: {
      DASHBOARD: 'Dashboard',
      COMPLETED: 'Completed',
      INTEGRATION:
        'Get ready for your first sale. Complete the setup to get started.',
      ORDER_AWAITING_CONFIRMATION: 'Order Awaiting Confirmation ',
      TOTAL_ORDER: 'Total Order',
      TOTAL_SALES_AMOUNT: 'Total Sales Amount',
      LATEST_CUSTOMERS: 'Latest Customers',
      LAST_ORDERS: 'Last Orders',
      TODAY_ORDERS: 'Today Orders',
      ANALYSIS_REPORT_NOT_FOUND: 'Analysis Report Not Found'
    },
    INTEGRATION: {
      BUSINESS_TITLE: 'Fill your store information',
      BUSINESS_DESCRIPTION: 'Fill in your store information for a safe sale.',
      PRODUCT_TITLE: 'Add your products or services',
      PRODUCT_DESCRIPTION: 'Get started by adding names, prices, and images.',
      PAYMENT_TITLE: 'Set up payment options',
      PAYMENT_DESCRIPTION:
        'Accept payments via credit card, Paypal, Stripe and more',
      SALES_CHANNEL_TITLE: 'Set up your sales channel',
      SALES_CHANNEL_DESCRIPTION:
        'Website, mobile app, SDK and more. Activate the most suitable sales channel for you.',
      SALES_TITLE: 'Make your first sale!',
      SALES_DESCRIPTION: 'Ship your first sale and product to your customers.'
    },
    SALES_CHANNEL: {
      GET_PAID_BY_COLLECTION: 'Get paid with Collection',
      EASY_COLLECTION_LANDING_PAGE_DEFINITION2_TITLE: 'Special for you!',
      EASY_COLLECTION_LANDING_PAGE_DEFINITION2_DESCRIPTION:
        "With Shopiroller Easy Collection, you can include your company's logo, trade name and contact information on your payment page, reflect your brand, and eliminate the risk of loss of sales.",
      EASY_COLLECTION_LANDING_PAGE_DEFINITION1_TITLE:
        'Start Getting Instant Payments!',
      EASY_COLLECTION_LANDING_PAGE_DEFINITION1_DESCRIPTION:
        'Get a Shopiroller Easy Collection solution with its secure infrastructure that you can easily add to your site without the need for a POS device or integration, and start collecting from your brand-specific website.',
      EASY_COLLECTION_LANDING_PAGE_TITLE: 'Activate payment by collection',
      REMOVE_EASY_COLLECTION:
        "Are you sure you want to delete '{{assetType}}' ? This action cannot be undone!",
      EASY_COLLECTION_ANYWHERE_ANYTIME_PAYMENT_OPPORTUNITY_DESCRIPTION:
        'Regardless of public holidays, weekends or weekdays, with Easy Collection, customers can easily collect collections at any time of the day. As long as your customer has internet access, can pay whenever wants.',
      EASY_COLLECTION_COMPATIBLE_WITH_ALL_DEBIT_AND_CREDIT_CARDS_DESCRIPTION:
        ' Easy Collection is compatible with all debit and credit cards. In this way, your customers can also benefit from installment options or card-specific advantages.',
      EASY_COLLECTION_MAIL_ORDER_DESCRIPTION:
        'Mail order forms are a thing of the past. You can easily perform all your e-collection transactions with the secure payment links you will create via Easy Collection.',
      EASY_COLLECTION_GENERAL_SETTINGS_DESCRIPTION:
        'Configure general settings for Easy Collection and your checkout screens.',
      EASY_COLLECTION_LOGO_FAVICON_DESCRIPTION:
        'Enter your favicon and logo that you want to appear on your Easy Collection pages. Thus, you can greet your customers with a corporate payment screen.',
      PERSONALIZE_EASY_COLLECTION_PAGE:
        'Personalize your Easy Collection pages to reflect your unique brand.',
      PUBLIC_LINK: 'Public Link',
      SELL_ON_YOUR_WEBPAGES: 'Sell On Your Web Pages',
      EASY_COLLECTION: 'Easy Collection',
      EASY_COLLECTION_DESCRIPTION:
        "Creating an active collection channel 24/7. Collect your payments quickly and securely from your customers' credit cards",
      SELL_ANYWHERE: 'Sell Anywhere',
      SELL_ON_WEBSITE: 'Sell on Website',
      SELL_ON_WEBSITE_DESCRIPTION:
        'Build a professional, 100% mobile-friendly online store that converts customers and sells.',
      MANAGE_WEBSITE: 'Manage',
      MANAGE_SHOPILINK: 'Manage',
      MANAGE_MOBILE: 'Manage',
      LEARN_MORE: 'Learn More',
      GET_PAID_WITH_LINK: 'Get paid with link',
      GET_PAID_WITH_LINK_DESCRIPTION:
        'Share your ShopiLink links on all digital channels and start getting paid securely.',
      SELL_ON_MOBILE_APP: 'Sell on Mobile App',
      SELL_ON_MOBILE_APP_DESCRIPTION:
        'Quickly design and launch a beautiful iOS and Android app for your store or brand. Increase your sales, boost your retention and build a brand your customers love..',
      SELL_ON_SOCIAL_MEDIA: 'Sell on Social Media',
      SELL_ON_SOCIAL_MEDIA_DESCRIPTION:
        'Connect your store to Facebook so you can display and sell your products directly through your Facebook business page and reach billions of potential customers.',
      SELL_ON_ONLINE_MARKET_PLACES:
        'Sell in marketplaces and different channels',
      SELL_ON_ONLINE_MARKET_PLACES_DESCRIPTION:
        'Attract more customers and sell on popular marketplaces, comparison sites.<br/>Strengthen your store with accounting software and e-invoice integration',
      WEBSITE: 'Website',
      WEBSITE_DESCRIPTION:
        'Your online store is set up and ready to go! Just fill in your business information and start selling right away.',
      SHOPILINK: 'ShopiLink',
      SHOPILINK_DESCRIPTION:
        'Create a link, sell whenever and wherever you want.',
      ANDROID_IOS: 'Android & ios',
      ANDROID_IOS_DESCRIPTION:
        'Turn your store into a mobile app that customers can download to their smartphones. Completely native, high performance.',
      ANDROID_IOS_DESIGN:
        'Personalize your mobile app to embellish your brand and identity.',
      ANDROID_IOS_GENERAL: 'Configure general settings for your mobile app.',
      SDK_INTEGRATION: 'SDK Integration',
      SDK_INTEGRATION_DESCRIPTION:
        'Shopiroller is the shopping cart solution developers rely on to craft e-commerce experiences of any kind. You can use it to add custom e-commerce to any mobile applications in minutes.',
      FACEBOOK_SHOP: 'Facebook Shop',
      FACEBOOK_SHOP_DESCRIPTION:
        'Facebook Marketplace is a convenient destination to discover, buy & sell items. People can find what they’re looking.',
      INSTAGRAM_SHOPPING: 'Instagram Shopping',
      INSTAGRAM_SHOPPING_DESCRIPTION:
        'Showcase your products on Instagram and convert your audience to buyers with shoppable Instagram posts.',
      GOOGLE_SHOPPING: 'Google Shopping',
      GOOGLE_SHOPPING_DESCRIPTION:
        'A quick and easy way to display and advertise your products across Google with Google‘s Shopping tab and Google Shopping Ads.',
      AMAZON: 'Amazon',
      AMAZON_DESCRIPTION:
        'List your products on Amazon, one of the most popular marketplaces on the web and get more customers.',
      HEPSIBURADA: 'Hepsiburada',
      HEPSIBURADA_DESCRIPTION:
        'You can make bulk and easy product entry to Hepsiburada store and instantly check your orders and issue invoices instantly.',
      TRENDYOL: 'Trendyol',
      TRENDYOL_DESCRIPTION:
        'You can make bulk and easy product entry to Trendyol store and instantly check your orders and issue invoices instantly.',
      POWERED_BY_MOBIROLLER: 'Powered by Mobiroller',
      MOBILE_SDK: 'Mobile SDK',
      COMING_SOON: 'Coming Soon',
      SELL_ON_MOBILE_APP_DESCRIPTION_IN_MOBILE_EMPTY_PAGE:
        'Turn your store into a mobile app that customers can download to their smartphones.',
      ACTIVATING_SHOPPING_APP: 'Activating Shopping App',
      HIGH_TECH_MOBILE_APPS: 'High Tech Mobile Apps',
      CHANGING_DEMANDS:
        'Changing demands or updates is not an issue now. Focus on your business and your mobile app will keep up automatically to stay agile.',
      IOS_AND_ANDROID_COMPATIBLE: 'iOS and Android compatible',
      IOS_AND_ANDROID_OPERATING_SYSTEM:
        'iOS and Android operating systems cover 97% of all mobile devices.<br /><br />You can create iOS and Android apps for your store. You can make it look and work exactly the way you want it to. The best part is, it requires no coding.',
      ENGAGE_YOUR_AUDIENCE: 'Engage your audience with Push Notifications',
      COMMUNICATE_IN_REALTIME:
        '  Communicate in real-time with push notifications, simple messages that go directly to a shopper’s phone to recover abandoned carts, run promotions, or simply generate new sales.',
      MOBILE_SALES_CHANNEL: 'Mobile Sales Channel',
      ACTIVATING_PAYMENT_BY_LINK: 'Activating Payment by Link',
      FORGOT_THE_OLD_WAYS: 'Forget the Old Ways!',
      MAIL_ORDER_DESCRIPTION:
        'Mail order forms are a thing of the past. You can easily perform all your e-collection transactions with the secure payment links you will create via ShopiLink.',
      COMPATIBLE_WITH_ALL_DEBIT_AND_CREDIT_CARDS:
        ' Compatible with All Debit and Credit Cards',
      COMPATIBLE_WITH_ALL_DEBIT_AND_CREDIT_CARDS_DESCRIPTION:
        ' ShopiLink is compatible with all debit and credit cards. In this way, your customers can also benefit from installment options or card-specific advantages.',
      ANYWHERE_ANYTIME_PAYMENT_OPPORTUNITY:
        'Anywhere and Anytime Payment Opportunity',
      ANYWHERE_ANYTIME_PAYMENT_OPPORTUNITY_DESCRIPTION:
        'Regardless of public holidays, weekends or weekdays, with ShopiLink, customers can easily collect collections at any time of the day. As long as your customer has internet access, can pay whenever wants.',
      SHOPPING_APP: 'Shopping App',
      YOUR_CONNECTED_APP: 'Your Connected Apps',
      YOUR_CONNECTED_APP_DESCRIPTION:
        ' Launch a world-class mobile app for your store. No-code native mobile apps besides.',
      LEARN_MORE_ABOUT_HOW_TO: ' Learn more about how to',
      CONNECT_A_NEW_APP: 'Connect a New App',
      ADD_FIRST_APP: "Oh, You haven't added any apps.<br />Add your first app.",
      CONNECT_NEW_APP: 'Connect a New App',
      DESIGN_AND_CONTENT: 'Design and Content',
      PERSONALIZE_SHOPILINK_PAGE:
        'Personalize your ShopiLink pages to reflect your unique brand.',
      SLIDER_DESCRIPTION:
        '  You can create various sliders to use in your application. You can direct the sliders you create to a category, product or any web address.',
      MANAGE_SLIDER: 'Manage Slider',
      CATEGORY_LAYOUT: 'Category Layout',
      CHOOSE_LAYOUT_FOR_CATEGORIES:
        'You can choose the layout for the categories on the main page.',
      GENERAL_SETTINGS: 'General Settings',
      GENERAL_SETTINGS_DESCRIPTION:
        'Configure general settings for ShopiLink and your checkout screens.',
      ACTIVE_PASSIVE_DESCRIPTION:
        'We can activate/passive the link payment feature at any time.',
      SLIDE_MANAGEMENT: 'Slide Management',
      APP_CONNECTED_SUCCESSFULLY: 'App connected successfully.',
      ENABLE_APP: 'Enable app',
      CONFIRM_MSG_FOR_ENABLE_APP: "Do you want to enable '{{appName}}'? ",
      DISABLE_APP: 'Disable app',
      CONFIRM_MSG_FOR_DISABLE_APP: 'Do you want to disable {{appName}}?',
      YOUR_WEBSITE: 'Your Website',
      SELL_ON_WEBSITE_DESCRIPTION_IN_WEBSITE_PAGE:
        'Instant Site combines everything you need to start selling online on a website. Make quick changes to your Instant Site with the easy-to-use editor.',
      ONLINE_STORE: 'Online Store',
      ONLINE_STORE_DESCRIPTION:
        'Your Instant Site with online storefront is already set up.Simply fill in your business information and start selling right away.',
      DESIGN_AND_CONTENT_DESCRIPTION:
        'Personalize your Instant Site and your storefront’s appearance to reflect your unique brand.',
      SITE_APPEARANCE: 'Site Appearance',
      SITE_APPEARANCE_DESCRIPTION:
        " Manage your store's themes. Add and publish themes to change your online store's appearance.",
      YOUR_CURRENT_THEME: 'Your Current Theme',
      CHANGE_THEME: 'Change Theme',
      CUSTOMIZE_DESCRIPTION:
        'Shopiroller is the shopping cart solution developers rely on to craft e-commerce experiences of any kind. You can use it to add custom e-commerce to any mobile applications in minutes.',
      HEADER_META_TAGS_SITE_VERIFICATION:
        'Header Meta Tags and Site Verification',
      WEBSITE_URL: 'Website URL',
      USER_OUR_DOMAIN: 'Use our subdomain',
      USER_OUR_DOMAIN_DESCRIPTION:
        'You can use our subdomain for your website, if you don’t have your own domain yet. For example, mystore.company.site or cool-tshirts.company.site',
      USE_YOUR_OWN_DOMAIN_NAME: 'Use your own domain name',
      USE_YOUR_OWN_DOMAIN_NAME_DESCRIPTION:
        'You can set up your own domain for your website. For example store.example.com',
      WEBSITE_URL_TIP1: "Sign in to your domain registrar's control panel",
      WEBSITE_URL_TIP2:
        'Create an A record for your example.com domain and point it to our IP-address: 94.130.182.174',
      WEBSITE_URL_TIP3:
        'To make your website also available at www.example.com, create a CNAME record for your domain and specify a www as a name/alias for it',
      SUCCESS_WE_DONE_IT: "Success! You've done it.",
      DOMAIN_NAME_AND_SITE_ADDRESS: 'Domain name and site address',
      DOMAIN_NAME_AND_SITE_ADDRESS_DESCRIPTION:
        'Help customers find you and stand out from the crowd by including the name of your business in your web address.',
      SITE_ADDRESS: 'Site Address',
      SITE_ADDRESS_DESCRIPTION:
        ' Link your website with your own purchased domain name if you have one or use a free address provided by Shopiroller.',
      CURRENT_ADDRESS: 'Current Address',
      CHANGE_ADDRESS: 'Change Address',
      SEO_SETTINGS: 'Seo Settings',
      SEO_SETTINGS_DESCRIPTION:
        'Control how your site interacts with search engines like Google.',
      HEADER_META_TAGS_SITE_VERIFICATION_DESCRIPTION:
        ' Some services such as Google Merchant Center, Google Search Console, and Pinterest require verification of site ownership.With the verification completed, you can access multiple traffic optimization tools provided by these services. To verify your site, add a special HTML tag to it.',
      ALLOW_SEARCH_ENGIN: 'Allow search engines to index Instant Site',
      ALLOW_SEARCH_ENGIN_DESCRIPTION:
        'This lets search engines display your Instant Site in search results. If you do not want your Instant Site to appear in search results, turn this option off.',
      CREATE_LINK: 'Create Link',
      EDIT_LINK: 'Edit Link',
      LINK_NAME: 'Link Name',
      LINK: 'Link',
      DELETE_LINKS: 'Delete Links',
      DELETE_LINK_TITLE: 'Deleted Link',
      DELETE_LINK_MESSAGE:
        "Delete '{{linkName}}'? This action cannot be undone.",
      DELETE_LINK_TITLE_SOME: 'Deleted Links',
      DELETE_LINK_MESSAGE_SOME: "Delete 'links'? This action cannot be undone.",
      CREATE_SHOPILINK: 'Create ShopiLink',
      NEW_SERVICE: 'New Service',
      SERVICE_NAME: 'Service Name',
      ADD_SERVICE: 'Add Service',
      DISPLAY_CATEGORY_NAME_BELOW_THE_IMAGE:
        'Display category name below the image',
      DISPLAY_CATEGORY_NAME_BELOW_THE_IMAGE_DESCRIPTION:
        'Your choice if all of your category images are styled differently or if you use icons to represent categories.',
      HIDE_CATEGORY_IMAGE: 'Hide category images',
      HIDE_CATEGORY_IMAGE_DESCRIPTION:
        ' Hide category images if your categories do not have an image uploaded,and display category names instead',
      HIDE_CATEGORY_NAME: 'Hide category names',
      HIDE_CATEGORY_NAME_DESCRIPTION:
        'Hide category names if they are already embedded in category images',
      FEATURE_IS_NOT_READY: 'This feature is not ready yet.',
      THIS_WILL_ONLY_VISIBLE_TO_YOU: 'This will only be visible to you',
      REQUEST_BILLING:
        'Request billing and shipping information from the buyer',
      CONTENTS: 'Contents',
      ADD_SELECT_PRODUCT: 'Add Select Product',
      ADD_NEW_SERVICE: 'Add New Service',
      LOGO_FAVICON: 'Logo and Favicon',
      LOGO_FAVICON_DESCRIPTION:
        'Enter your favicon and logo that you want to appear on your ShopiLink pages. Thus, you can greet your customers with a corporate payment screen.',
      SELECT_IMAGE_AS_LOGO: 'Select an image as your logo',
      SELECT_IMAGE_AS_FAVICON: 'Select an image as your favicon',
      WE_ACTIVE_PASSIVE_LINK:
        'We can activate/passive the link payment feature at any time',
      LINK_WORKS_ONLY:
        "The link payment feature only works with the PARAM payment method. Please go to the <a href='/store-settings/payment' class='alert-link'> payment methods </a>screen and activate the PARAM payment method.",
      APP_NAME: 'App Name',
      SUCCESSFUL_CONNECTION_OF_APP: 'New App connected successfully',
      SUCCESSFUL_EDIT_OF_APP: 'Your app edited successfully',
      FAILED_CONNECTION_OF_APP: 'Unfortunately, connecting app failed!',
      FAILED_EDIT_OF_APP: 'Unfortunately, editing app failed!',
      OUT_MOBILE_CHANNEL_LIMIT:
        "You're not allowed to connect to more than 4 app",
      SUCCESSFUL_ENABLE_OF_APP: 'Your app is enabled successfully',
      FAILED_ENABLE_OF_APP: 'Unfortunately, Enabling your app is failed!',
      SUCCESSFUL_DISABLE_OF_APP: 'Your app is disabled successfully',
      FAILED_DISABLE_OF_APP: 'Unfortunately, Disabling your app is failed!',
      ACTIVE_PASSIVE_DESCRIPTION_FOR_MOBILE_CHANNEL:
        'You can make the mobile application sales channel active/passive whenever you want.',
      ACTIVATION_OF_SALE_CHANNEL_FAILED:
        'Unfortunately, the activation of {{channelType}} sale channel failed!',
      REACHED_TO_MAX_CONNECTED_APP: 'You are not allowed to connect new app!',
      SALE_CHANNEL_ALREADY_EXIST: '{{saleChannel}} already exist!',
      SELECT_PLATFORM: 'Select your desired platform',
      SALES_CHANNEL_ENABLED:
        '{{saleChannelType}} sale channel enabled successfully',
      SALES_CHANNEL_DISABLED:
        '{{saleChannelType}} sale channel disabled successfully',
      CHANGING_SALE_CHANNEL_ACTIVATION_FAILED:
        'Changing {{saleChannelType}} sale channel activation failed!',
      YOUR_MOBILE_CHANNEL_IS_PASSIVE:
        'Your mobile sale channel is passive right now, this could lead you to authentication problem in your mobile app!',
      NO_SHOPILINK_MESSAGE:
        'Add Shopilink! Use Shopilink to package your products in your store and sell them at once.',
      DELETE_SHOPILINK: {
        TITLE: 'Deleted Shopilink',
        MESSAGE: "Delete '{{shopilinkName}}'? This action cannot be undone.",
        TITLE_SOME: 'Deleted Shopilinks',
        MESSAGE_SOME: "Delete 'Shopilinks'? This action cannot be undone."
      },
      SUCCESSFUL_REMOVING_FAVICON_LOGO:
        '{{settingType}} of your channel removed successfully',
      FAILED_REMOVING_FAVICON_LOGO:
        'Removing the {{settingType}} of your channel failed!',
      SUCCESSFUL_UPDATING_FAVICON_LOGO:
        '{{settingType}} of your channel updated successfully',
      FAILED_UPDATING_FAVICON_LOGO:
        'Updating the {{settingType}} of your channel failed!',
      DELETE_LINK: 'Delete link',
      NO_PRODUCT_SERVICE_SELECTED: 'No product/service has been selected yet.',
      MAX_STOCK_EXCEEDED:
        "One of the items count in your basket is less than it's count in stock",
      NOT_ENOUGH_COUNT_IN_STOCK: '{{productName}} stock count is less than 1',
      SHOPILINK_PASSIVE_ALERT_MSG:
        'Your ShopiLink sales channel is currently inactive, this may cause the links you created before to not work!',
      PRODUCT_NOT_FOUND:
        "Your operation is fulfilled successfully but we were unable to add some of the selected products because some of the products couldn't be found",
      OUT_OF_STOCK_ERROR:
        "One or more of the products in the link is out of stock.That's why your payment link has been disabled, Please check stocks",
      PRODUCT_IS_OUT_OF_STOCK_ERROR:
        'This Product is out of stock, we can not add it to your shopilink',
      SHOPILINK_ADDED_PARTIALLY:
        'Your operation is fulfilled successfully but we were unable to add some of the selected products due to out of stock in inventory',
      PAYMENT_IS_NOT_ACTIVE_MESSAGE:
        'You must activate an online payment method for the link payment feature. Please go to the payment methods page and activate a payment method',
      YOUR_WEB_CHANNEL_IS_PASSIVE:
        'Your web sale channel is passive right now, this could lead you to authentication problems on your website!',
      DOMAIN_UPDATED_SUCCESSFULLY: 'Your store domain updated successfully',
      DOMAIN_UPDATED_FAILED: 'Unfortunately, your store domain update failed!',
      META_TAGS_UPDATED_SUCCESSFULLY: 'Your meta tags updated successfully',
      META_TAGS_UPDATED_FAILED:
        'Unfortunately, your store meta tags update failed!',
      INVALID_DOMAIN:
        'One of the domains is invalid or already taken by another user',
      YOUR_WEB_SETTING_HAS_NO_INFO:
        'Unfortunately, There is no info for your web setting at the moment',
      SELL_ON_GOOGLE: 'Sell on Google',
      SELL_ON_GOOGLE_DESCRIPTION:
        'A quick and easy way to showcase your products across Google',
      SELL_ON_GOOGLE_DESCRIPTION_IN_ACTIVATE_PAGE:
        'Turn your store into a mobile app that customers can download to their smartphones.',
      LIST_YOUR_PRODUCTS: 'List your products across Google for free',
      LIST_YOUR_PRODUCTS_DESCRIPTION:
        'Get started by providing information about your products. Select your country and category of your products and your package will be ready for selling your products on Google.',
      CUSTOMERS_CLICK_TO_PURCHASE_YOUR_PRODUCTS:
        'Customers click to purchase your products',
      CUSTOMERS_CLICK_TO_PURCHASE_YOUR_PRODUCTS_DESCRIPTION:
        'Your products can appear on Google Search, Maps, and more, when users search for your products. Once a customer clicks, you can direct them to your online store to complete their purchase.',
      GORW_YOUR_BUSINESS_WITH_PERSONALIZED_INSIGHTS:
        'Grow your business with personalized insights',
      GORW_YOUR_BUSINESS_WITH_PERSONALIZED_INSIGHTS_DESCRIPTION:
        "We'll provide actionable insights about how customers interact with your business and products so you can help increase traffic to your site or local store.",
      HELP_BOOST_YOUR_SALES_WITH_PAID_ADVERTISING:
        'Help boost your sales with paid advertising',
      HELP_BOOST_YOUR_SALES_WITH_PAID_ADVERTISING_DESCRIPTION:
        "When you're ready, you can promote your products to reach more customers with advertising that drives sales.",
      ACTIVATING_GOOGLE_SHOPPING: 'Activating Google Shopping',
      GOOGLE_STORE: 'Google Store',
      GOOGLE_STORE_DESCRIPTION:
        'Provide a package of your products in order to ship on the Google store',
      PROVIDE_A_PACKAGE: 'Provide a package',
      DOWNLOAD_PRODUCTS_FILE: 'Download Products File',
      GOOGLE_PRODUCTS_DESCRIPTION:
        'Select the country and category of your products, then your package will be ready in a file or through a URL. <br/> You can directly import your file or paste your package URL in the Google merchant center and let Google import your products.',
      PACKAGE_URL: 'Package URL',
      MAGENTY: 'Magenty',
      MAGENTY_DESCRIPTION:
        'With Magenty integration, you can transfer your product, price, stock information with a single click, and manage all transactions from a single screen',
      SELL_ON_MAGENTY: 'Sell on Magenty',
      SELL_ON_MAGENTY_DESCRIPTION:
        'Easily manage your <br/><p><strong>Product, Stock, Order, Invoice, Shipping</strong><br/>and <strong>Message Management</strong> from a single<br/>screen. Start the integration now and publish hundreds of<br/>products in many marketplaces.</p>',
      ACTIVATING_MAGENTY: 'Activate Magenty',
      BULK_PRODUCT_TRANSFER: 'Bulk Product Transfer',
      BULK_PRODUCT_TRANSFER_DESCRIPTION:
        'You can transfer your products in bulk to the marketplaces in seconds. In a few easy steps, review all your products and speed up your transfer processes with smart reports.',
      ADVANCED_REPORTING: 'Advanced Reporting',
      ADVANCED_REPORTING_DESCRIPTION:
        'Create reports as you want with flexible and advanced filters and download them as Excel. You can see your turnover, profit, number of sales both on the chart and the table.',
      MAGENTY_CONFIGURATION_SUCCESSFULY_COMPLETED:
        'Congrats, You configured Magenty successfully',
      MAGENTY_CONFIGURATION_FAILED:
        'Unfortunately, the configuration process failed!',
      CONFIGURE_MAGENTY: 'Configure Magenty',
      CONFIGURE_MAGENTY_WARNING:
        "Please be careful about your configuration, it's only possible to configure Magenty for one time due to policies",
      REQUEST_FOR_MAGENTY_CONFIGURATION_CHANGE:
        'Your configuration is <b class="h6">{{status}}</b>, If you want to change your configuration please contact the support team',
      UNSPECIFIED_MAGENTY_CONFIGURATION_STATUS:
        'Your configuration status is <b class="h6">Unspecified</b> now, If you want to change your configuration please contact the support team',
      MAGENTY_CONFIGURATION: 'Magenty Configuration',
      MAGENTY_CONFIGURATION_CONFIRMATION_MESSAGE:
        'Are you sure you want to set the current configuration for Magenty?',
      MAGENTY_OWNER_NAME: "Store Owner's Name",
      MAGENTY_OWNER_SURNAME: "Store Owner's Surname",
      MAGENTY_PHONENUMBER: 'GSM(Phone Number)'
    },
    APP_SUMO: {
      STORE_LIST: 'store list',
      UPGRADABLE_STORE_LIST: 'Upgradable store list',
      YOU_HAVE_X_ACTIVE_STORE:
        'You have {{ activeProjectsCount }} active stores',
      STORE_NAME: 'store name',
      YOU_CAN_UPGRADE_X_STORES: 'You can upgrade {{ projectsCount }} stores',
      NOT_ALLOWED_MORE_STORES:
        "Your plan limit exceeded, you can't upgrade another store",
      DOWNGRADE_PLAN: 'Downgrade plan',
      DOWNGRADE_PLAN_MESSAGE:
        "Are sure you want to downgrade plan for '{{packageName}}'?",
      SUCCESSFUL_DOWNGRADE: 'Your store plan downgraded successfully',
      UPGRADE_PLAN: 'Upgrade plan',
      UPGRADE_PLAN_MESSAGE:
        "You're going to upgrade '{{packageName}}' store plan, are you sure you want to accomplish this process?",
      SUCCESSFUL_UPGRADE: 'Your store plan upgraded successfully',
      MANAGE_APP_SUMO: 'Manage AppSumo',
      NO_ACTIVE_PACKAGES: 'No Active Package',
      APP_SUMO_INFO: 'AppSumo Info',
      PROJECT_LIST: 'project list',
      YOU_HAVE_X_ACTIVE_PROJECT:
        'You have {{ activeProjectsCount }} active projects',
      PROJECT_NAME: 'project name',
      UPGRADABLE_PROJECT_LIST: 'Upgradable project list',
      YOU_CAN_UPGRADE_X_PROJECTS:
        'You can upgrade {{ projectsCount }} projects',
      DESCRIPTION:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugit esse nulla enim facilis excepturi sint quam vel hic quasi maxime nam, evenietharum quae, quibusdam sapiente aut minima eaque rem!',
      NOT_ALLOWED_MORE_PROJECTS:
        "Your plan limit exceeded, you can't upgrade another project"
    },
    DISCOUNT: {
      DISCOUNT: 'Discount',
      DISCOUNTS: 'Discounts',
      DISCOUNT_CODE: 'Discount Code',
      DISCOUNT_AMOUNT: 'Discount Amount',
      SHOPPING_TOTAL: 'Shopping Total',
      EXPIRY_DATE: 'Expiry Date',
      CODE_QUANTITY: 'Code Quantity',
      USED: 'Used',
      STATUS: 'Status',
      NO_DISCOUNT_MESSAGE:
        'Add your first discount! Add your discounts here and expect an extreme selling amount.',
      ADD_DISCOUNT: 'Add Discount',
      CREATE_NEW_DISCOUNT: 'Create New Discount',
      EDIT_EXISTING_DISCOUNT: 'Edit Existing Discount',
      TOTAL_COUPON_LIMIT: 'Total Coupon Limit',
      TOTAL_USAGE_LIMIT_OF_COUPON: 'The total usage limit of the coupon',
      DISCOUNT_HAS_AN_EXPIRY_DATE: 'Discount has an expiry date?',
      SCHEDULE_DISCOUNT_DEACTIVATE_IN_FUTURE:
        'Schedule the discount to deactivate in the future.',
      EDIT: 'Edit Discount',
      CHECKOUT_CODE:
        'The code your customers will enter during checkout. Uppercase letters and numbers only',
      DELETE_DISCOUNT: 'Delete Discount',
      COUPON_ALREADY_EXIST:
        'You need to change your coupon code cause it already exists',
      DISCOUNT_AMOUNT_ERROR:
        'The discount amount can not be greater than the total shopping price',
        COUPON_CODE:"Coupon Code"
    },
    APP_STORE: {
      BIZIM_HESAP: 'Bizim Hesap',
      BIZIM_HESAP_DESCRIPTION:
        'Bizim Hesap is an integration that allows you to manage your business while keeping your preliminary accounting. Many features, including automatic invoice integration, are waiting for you.',
      ACCOUNTING:"Accounting",
      APP_STORE: 'App Store',
      APP_MARKET: 'App Market',
      MY_APPS: 'My Apps',
      REQUEST_INTEGRATION: 'Request an integration',
      LOOKING_FOR_INTEGRATION: "Looking for an integration that isn't here?",
      REQUEST: 'Request',
      SHOW_ALL: 'Show all',
      MARKETING: 'Marketing',
      SHIPPING_DELIVERY: 'Shipping and Delivery',
      CUSTOMER_SUPPORT: 'Customer Support',
      CONVERSATION: 'Conversation',
      ANALYTICS_REPORT: 'Analytics & Report',
      GOOGLE_ANALYTICS: 'Google Analytics',
      GOOGLE_ANALYTICS_DESCRIPTION:
        'Add Google Analytics tracking to your store and be informed about your store metrics including all e-commerce data',
      GOOGLE_ANALYTICS_PAGE_DESCRIPTION:
        'You can access statistical information about users visiting your Shopiroller store on Google Analytics and analyze the usage habits of your users.',
      GOOGLE_ANALYTICS_MEASUREMENT_ID: 'Google Analytics Measurement ID',
      GOOGLE_ANALYTICS_SUCCESSFUL_UPDATE:
        'Google Analytics measurement id Updated Successfully',
      GOOGLE_TAG_MANAGER: 'Google Tag Manager',
      GOOGLE_TAG_MANAGER_DESCRIPTION:
        'Google Tag Manager is a tag management system that allows you to quickly update measurement codes & tags on your website or mobile app',
      GOOGLE_TAG_MANAGER_CONTAINER_ID: 'Google Tag manager Container ID',
      GOOGLE_TAG_MANAGER_SUCCESSFUL_UPDATE:
        'Google Tag Manager container id updated successfully',
      GUEST_SHOPPING: 'Guest Shopping',
      GUEST_SHOPPING_DESCRIPTION:
        'This feature makes it possible to shop wthout registration. Now, without having to open an account, your customers can shop quickly and securely by simply defining an e-mail address.With the guest shopping feature, your users can discover and buy your products faster. In addition, thanks to this feature, your customers can easily track their orders on the inquiry screen.',
      GUEST_SHOPPING_SHORT_DESCRIPTION:
        'Guest shopping makes it possible to shop without registration. Now, without having to open an account, your customers can shop quickly and securely by simply defining an e-mail address',
      GUEST_SHOPPING_ACTIVE_MESSAGE:
        'Congratulations. You have activated the Guest Shopping feature. Now your users can order without being a member.',
      GUEST_SHOPPING_DE_ACTIVE_MESSAGE:
        'The Guest shopping feature has been removed from your store',
      CART_REMINDER: 'Cart Reminder',
      CART_REMINDER_DESCRIPTION:
        'Notify your customers who leave the cart before completing the payment with reminders and bring your visitors back',
      BACK_IN_STOCK_ALERTS: 'Back in Stock Alerts',
      BACK_IN_STOCK_ALERTS_DESCRIPTION:
        'Allow your customers to subscribe to your out-of-stock items and receive notifications when that item is restocked.',
      PRODUCT_REVIEWS: 'Product Reviews',
      PRODUCT_REVIEWS_DESCRIPTION:
        'Send notifications for your customers to rate products and share their experiences',
      WHATS_APP_DESCRIPTION:
        "Improve user engagement with WhatsApp 'Contact Us' button. Allow users to contact you via WhatsApp",
      ALL_APPS: 'All apps',
      CONTACT_US_BUTTON: "WhatsApp 'Contact Us' Button",
      IMPROVE_USER_ENGAGEMENT:
        "Improve user engagement with WhatsApp 'Contact Us' button.",
      ADD_APP: ' Add App',
      ALLOW_USERS_TO_CONNECT_YOU_VIA_WHATSAPP:
        'Allow users to contact you via WhatsApp',
      EXCELLENCE_TO_COMMUNICATE_WITH_YOUR_USERS:
        'Excellence to communicate with your users. Make your work easier from your website or mobile application.',
      CLICK_TO_CALL:
        'Click-to-call, make it easy for your site visitors to contact you from anywhere on your website via their mobile phones with WhatsApp Message.',
      USE_OUR_COMMUNICATION_FEATURES:
        'Use our communication features and let your users easily contact you via WhatsApp, Phone and more.',
      THIS_APP_WAS_DEVELOPED_BY: 'This app was developed by',
      ACCOUNT_NUMBER: 'Account Number',
      ACCOUNT_NAME: 'Account Name',
      ACTIVATE_YOUR_WHATSAPP_BUTTON:
        'Activate your Whatsapp button on your website',
      MOBILE_APP: 'Mobile App',
      ACTIVATE_YOUR_WHATSAPP_BUTTON_IN_MOBILE_APPLICATION:
        'Activate your Whatsapp button in the mobile application',
      ACTIVATING_WHATS_APP_FAILED_MSG:
        'Unfortunately, WhatsApp activation failed',
      WHATS_APP_ACTIVATED: 'Your Whatsapp activation fulfilled successfully',
      WHATS_APP_CONFIGURATION_UPDATED:
        'Your Whatsapp configuration updated successfully',
      ABANDONED_CART_AUTOMATION: 'Abandoned cart automation',
      NOTIFY_CUSTOMERS_WHO_LEAVE_THE_CART:
        'Notify customers who leave the cart before completing the payment by e-mail',
      SET_WHEN_TO_SEND_EMAIL_AFTER_CART_ABANDONMENT:
        'Set when to send email after cart abandonment',
      REMINDER_NOT_FOUND:
        "You didn't set a reminder for your shopping carts yet!",
      REMINDER_CONFIGURATION_UPDATED:
        'Your reminder configuration updated successfully',
      UPDATING_REMINDER_CONFIGURATION_FAILED_MSG:
        'Unfortunately, Reminder configuration update failed'
    },
    REGION: {
      REGION_LOCALIZATION: 'Regions & Localization',
      ADD_NEW_REGION: 'Add New Region',
      CREATE_NEW_REGION: 'Create New Region',
      DYNAMIC_PRICING: 'Dynamic Pricing',
      DYNAMIC_PRICING_DESCRIPTION:
        'You can start selling your products in your store by converting them to the value in the currency you choose',
      TARGET_CURRENCY: 'Target Currency',
      ROUNDING_PREFERENCES: 'Rounding Preference',
      ROUNDING_PREFERENCES_DESCRIPTION:
        'After the currency is converted, you can choose how you want to round the last or last 2 digits of the fraction',
      EDIT_EXISTING_REGION: 'Edit Existing Region',
      DELETE_REGION: 'Delete Region',
      ALL_COUNTRIES: 'All countries',
      OTHER_COUNTRIES: 'Other countries'
    },
    SUBSCRIPTION: {
      SCALE_PLAN: 'Scale Plan',
      UPGRADE_TO_GET_THE_APP: 'Upgrade to get the app',
      REQUIRES_SUBSCRIPTION:
        'You must upgrade your package to {{planType}} in terms of having access to this feature, if you click you will be redirected to purchase page of this package',
      UPGRADE_PACKAGE_BUTTON:
        'Your current package is {{planType}}, if you want to upgrade it you can click on this button',
      UPGRADE_TO_ACTIVATE_PAYPAL: 'Upgrade To Activate PayPal',
      UPGRADE_TO_ACTIVATE_IYZICO: 'Upgrade To Activate Iyzico',
      UPGRADE_TO_ACTIVATE_STRIPE: 'Upgrade To Activate Stripe',
      UPGRADE_TO_ACTIVATE_PAYTR: 'Upgrade To Activate PayTR',
      UPGRADE_TO_ACTIVATE_BIRLESIKODEME: 'Upgrade To Activate Birlesik Odeme',
      UPGRADE_TO_ACTIVATE_CASH_ON_DELIVERY: 'Upgrade To Activate Pay At Door',
      UPGRADE_TO_ACTIVATE_EFT: 'Upgrade To Activate EFT',
      UPGRADE_TO_ADD_MORE_VARIANT: 'Upgrade To Add More Variant',
      UPGRADE_TO_NOTIFY_YOUR_CUSTOMERS:
        'Upgrade To Notify Your Customers By Email',
      UPGRADE_TO_ACTIVATE_WHATSAPP: 'Upgrade To Activate WhatsApp',
      UPGRADE_TO_ACTIVATE_SHOPILINK: 'Upgrade To Activate Shopilink',
      UPGRADE_TO_ACTIVATE_MOBILE_SALE_CHANNEL:
        'Upgrade To Activate Mobile Sale Channel',
      INVOICES_PACKAGES: 'Invoices and Packages',
      STARTER: 'Starter',
      STARTER_PACKAGE_DESCRIPTION: '"Hello" package to e-commerce',
      PROFESSIONAL: 'Professional',
      PROFESSIONAL_PACKAGE_DESCRIPTION: 'The best to explore global trade',
      BUSINESS: 'Business',
      BUSINESS_PACKAGE_DESCRIPTION: 'Best for scaling businesses',
      SUBSCRIPTION: 'Subscription',
      MOST_POPULAR: 'Most popular',
      PAYMENT_DIDNT_FULFILLED: "Unfortunately, your payment didn't fulfill",
      CONGRATULATIONS: 'Congrats',
      PACKAGE_PURCHASE_SHORT_MESSAGE:
        'Your package has been successfully purchased',
      PACKAGE_PURCHASE_LONG_MESSAGE:
        'You will be able to use the features of the purchased package from now on',
      PAYMENT_FAILED: 'Payment failed',
      PACKAGE_NOT_PURCHASED_SHORT_MESSAGE:
        "Unfortunately, We can't upgrade your package",
      PACKAGE_NOT_PURCHASED_LONG_MESSAGE:
        "Your payment operation didn't complete due to unknown reasons. Please try again later",
      UPGRADE_TO_ADD_MORE_LANGUAGES: 'Upgrade To Add More Languages',
      YOUR_CURRENT_PACKAGE: 'Your current package',
      YOU_ACTIVATED_THIS_PACKAGE_BEFORE: 'You activated this package before',
      PACKAGE_TYPE: 'Package Type',
      BILLING_SUBSCRIPTION_SETTINGS: 'Billing & Subscription Settings',
      PACKAGES: 'Packages',
      CARD_NUMBER: 'Card Number',
      EXPIRATION_DATE_FORMAT: 'MM / YY',
      CVC_IS_NOT_VISIBLE:
        'CVC is not visible to you due to security reasons, you can only set a new one',
      SET_CREDIT_CARD_AGAIN:
        'Due to security reasons, you must set your credit card number once again!',
      EXP_YEAR_MUST_BE_GREATER_THAN_CURRENT_YEAR:
        "'Expiration Year' must be greater than the current year",
      CARD_WAS_DECLINED:
        'Your card was declined. Probably your request was in test mode, but you used a non-test card',
      PACKAGE_FEATURES: 'Package Features',
      DEALER_BALANCE_CURRENCY: 'Dealer Balance Currency',
      COMING_SOON: 'Coming Soon',
      FOLLOWING: 'and the following'
    },
    WEB_HOOK: {
      WEB_HOOK: 'Web Hook',
      WEB_HOOK_DESCRIPTION:
        'WebHook issues requests after each operation that happens in the project, for instance, after a brand is created/updated or deleted even when a new order is created, WebHook will come to play',
      WEB_HOOK_ACTIVE_MESSAGE:
        'Congratulations. You have activated the Hook feature',
      WEB_HOOK_DE_ACTIVE_MESSAGE:
        'The Hook feature has been removed from your store',
      MAX_RETRY_COUNT: 'Max retry count',
      EMPTY_WEB_HOOK_SECTION_MESSAGE:
        'Create your first webhook! Trigger events after the operations and see what would be the magic behind that',
      CREATE_NEW_WEBHOOK: 'Create New Webhook',
      CREATE_WEBHOOK: 'Create Webhook',
      REQUESTS: 'Requests',
      DELETE_REQUEST: 'Delete Request',
      HEADERS: 'Headers',
      ADD_NEW_HEADER: 'Add New Header',
      DELETE_HEADER: 'Delete Header',
      MAXIMUM_REQUESTS_HEADER_MESSAGE:
        "You're only allowed to add 4 headers in your requests",
      MAXIMUM_REQUESTS_MESSAGE:
        "You're only allowed to add 3 requests in each Webhook",
      ADD_REQUEST: 'Add New Request',
      ADD_WEB_HOOK: 'Add Webhook',
      EDIT_WEB_HOOK: 'Edit Webhook',
      DELETE_DIALOG_TITLE: 'Delete Webhook',
      DELETE_DIALOG_MESSAGE:
        "Delete '{{webhookName}}'? This action cannot be undone.",
      SUCCESSFUL_DELETE: 'Webhook deletion fulfilled successfully',
      FAILED_DELETE: 'Unfortunately, Webhook deletion failed!',
      SUCCESSFUL_CREATE: 'Webhook creation fulfilled successfully',
      FAILED_CREATE: 'Unfortunately, Webhook creation failed!',
      SUCCESSFUL_EDIT: 'Webhook update fulfilled successfully',
      FAILED_EDIT: 'Unfortunately, Webhook update failed!',
      MAXIMUM_WEB_HOOK_ERROR_MESSAGE:
        "You're only allowed to add 10 webhook in each Webhook"
    },
    primeng: {
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Great then or equals',
      is: 'Is',
      isNot: 'Is not',
      before: 'Before',
      after: 'After',
      clear: 'Clear',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Yes',
      reject: 'No',
      choose: 'Choose',
      upload: 'Upload',
      cancel: 'Cancel',
      dayNames: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      today: 'Today',
      weekHeader: 'Wk',
      weak: 'Weak',
      medium: 'Medium',
      strong: 'Strong',
      passwordPrompt: 'Enter a password',
      emptyMessage: 'No results found',
      emptyFilterMessage: 'No results found'
    }
  }
};
